import { CommandType, ITicketsDTO } from "./TicketsDTO";
export enum RelayActionMass {
    RelayOff = 0,
    RelayOn,
    RelayStatus,
  }

export interface ISerialCommand {
    serial: string;
    commandType: RelayActionMass;
}

export interface IProcessCommand {
    serial: string;
    commandType: CommandType;
}


export class SerialComamndDTO implements ISerialCommand {
    serial: string;
    commandType: RelayActionMass;
    static getCommandTypeClass(serialComand: ISerialCommand): string {
        const commandType: RelayActionMass = serialComand.commandType;

        switch (commandType) {
            case RelayActionMass.RelayOn:
                return 'badge badge-success mr-1'
            case RelayActionMass.RelayOff:
                return 'badge badge-danger mr-1'
            case RelayActionMass.RelayStatus:
                return 'badge badge-info mr-1';
        }
    }
}

export enum ProcessType {
    MassCommand,
    ScheduleRecoverMassMemorySmc,
    ScheduledCommand,
}

export interface IProcessCreate {
    serialCommands: ISerialCommand[];
    processType: ProcessType;
}

export interface ITicketResult {
    serial: string;
    created: boolean;
    reason: string;
}

export interface IProcessCreatedResponse {
    process: IRelayActionProcess;
    ticketResults: ITicketResult[];
}



export interface IRelayActionProcess {
    processId?: string;
    user?: string;
    updatedAt?: Date;
    tickets?: ITicketsDTO[];
    ticketsExecuting?: number;
    ticketsWaiting?: number;
    ticketsFailed?: number;
    ticketsSuccess?: number;
    totalTickets?: number;
    ticketsScheduled?: number;
    ticketsRelayOn?: number;
    ticketsRelayOff?: number;
    ticketsGetRelayStatus?: number;
    processType?: ProcessType;
}

export class RelayActionProcessDTO implements IRelayActionProcess {
    processId: string;
    user: string;
    updatedAt: Date;
    tickets: ITicketsDTO[];
    ticketsExecuting: number;
    ticketsWaiting: number;
    ticketsFailed: number;
    ticketsSuccess: number;
    totalTickets: number;
    ticketsRelayOn: number;
    ticketsRelayOff: number;
    ticketsGetRelayStatus: number;
    ticketsScheduled: number;
    processType: ProcessType;

}


export class ProcessCreatedDTO implements IProcessCreate {
    serialCommands: ISerialCommand[];
    processType: ProcessType = ProcessType.MassCommand;

    constructor(serialComamnd: ISerialCommand[]) {
        this.serialCommands = serialComamnd;
    }
}
