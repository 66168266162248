
                    export const environment = {
  production: true,
  apiUrlBack: "http://desenvolvimento.sw.nepen.org.br:81",
  tokenWhitelistedDomains: "http://desenvolvimento.sw.nepen.org.br:81/",
  tokenBlacklistedDomains: "http://desenvolvimento.sw.nepen.org.br:81/users/login",
  websocketExpiration: 1572986429,
  servicesNotes: "http://dev.alpha.nepen.org.br:8873",
  auditService: "http://206.42.56.79:100",
  returnUrl: "returnUrlIog",
  currentUser: "iogCurrentUser",
  token: "iogToken",
  smiAlarmesCriticos: 'smiAlarmesCriticosIog',
  smcAlarmesCriticos: 'smcAlarmesCriticosIog',
  appDownloadUrl: 'http://iog.nepen.org.br:1011/app-release.apk',
  currentLanguage: 'iogCurrentLanguage',
  nansenTransparent: "TRANSPARENT", 
  suportedLanguage: [
    {
      name: 'Portugês (Brasil)',
      value: 'pt-BR',
      flag: 'fi-br'
    },
    {
      name: 'Inglês (US)',
      value: 'en-US',
      flag: 'fi-us'
    },
    {
      name: "Espanhol",
      value: 'es',
      flag: 'fi-es'
    }
  ],
  appVersion: "1.5.0",
  client: false,
  clientTitle: 'MDC Equatorial' 
}

                    