import { Injectable } from "@angular/core";
import { IogHttpService } from "src/app/security/iog-http.service";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: 'root' })
export class QualityIndicatorsServices {
  static commandsUrl = "/commands/";
  static qeeBaseUrl = "/qee-indicators/";
  static qeeAuditBaseUrl = "/qee-indicators-audit";

  static qeeMonthlyBaseUrl = "/qee-indicators-monthly/";

  constructor(private http: IogHttpService) { }

  getQualityIndicator(
    serial: string,
    pageSize: number = 15,
    page: number = 1,
    sortOrder: string = "datetimeFinal-desc",
    startDateTime: string = "",
    endDateTime: string = ""
  ) {
    return this.http
      .get(
        environment.apiUrlBack +
        QualityIndicatorsServices.qeeBaseUrl +
        `${serial}?pageSize=${pageSize}&page=${page}&sortOrder=${sortOrder}&startDateTime=${startDateTime}&endDateTime=${endDateTime}`
      )
      .map((response) => response.valueOf());
  }

  
  getQualityIndicatorAudit(
    serial: string,
    pageSize: number = 15,
    page: number = 1,
    sortOrder: string = "datetimeFinal-desc",
    startDateTime: string = "",
    endDateTime: string = ""
  ) {
    return this.http
      .get(
        environment.apiUrlBack +
        QualityIndicatorsServices.qeeAuditBaseUrl +
        `?serial=${serial}&pageSize=${pageSize}&page=${page}&sortOrder=${sortOrder}&startDateTime=${startDateTime}&endDateTime=${endDateTime}`
      )
      .map((response) => response.valueOf());
  }

  
  getQualityIndicatorMonthly(
    serial: string,
    pageSize: number = 15,
    page: number = 1,
    sortOrder: string = "datetimeFinal-desc",
    startDateTime: string = "",
    endDateTime: string = ""
  ) {
    return this.http
      .get(
        environment.apiUrlBack +
        QualityIndicatorsServices.qeeMonthlyBaseUrl +
        `?serial=${serial}&pageSize=${pageSize}&page=${page}&sortOrder=${sortOrder}&startDateTime=${startDateTime}&endDateTime=${endDateTime}`
      )
      .map((response) => response.valueOf());
  }

  requestQualityIndicators(serial: string) {
    return this.http.put(
      environment.apiUrlBack +
      QualityIndicatorsServices.commandsUrl +
      QualityIndicatorsServices.qeeBaseUrl +
      `${serial}`,
      ""
    );
  }

  recoveryQEE(serial: string, amountOfSample: number) {
    return this.http.post(
      environment.apiUrlBack +
      QualityIndicatorsServices.commandsUrl +
      `recover-event-qee-indicator/${serial}?entriesRequested=${amountOfSample}`,
      {}
    );
  }

  recoveryQEEEasyTrafo(serial: string) {
    return this.http.put(
      environment.apiUrlBack +
      QualityIndicatorsServices.commandsUrl +
      "qee-indicators/" +
      `${serial}`,
      {}
    );
  }

  uploadQeeFile(serial: string, qeeFile: any) {
    return this.http.post(
      environment.apiUrlBack +
      QualityIndicatorsServices.qeeBaseUrl +
      `${serial}`,
      qeeFile
    );
  }

  requestMonthlyQEE(serial: string, monthReference: string) {
    return this.http.get(
      environment.apiUrlBack +
      QualityIndicatorsServices.qeeBaseUrl +
      `quality-read?serial=${serial}&reference=${monthReference}`
    );
  }

  requestMonthlyQEEBatch(groupId: string, monthReference: string) {
    return this.http.get(
      environment.apiUrlBack +
      QualityIndicatorsServices.qeeBaseUrl +
      `batch-quality-read/${groupId}?reference=${monthReference}`
    );
  }
}
