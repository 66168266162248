import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as XLS from "xlsx";
import {
  MeterNotComissionedDTO,
  MeterPhase,
  MeterDTO,
} from "src/app/core/models/MeterDTO";
import { Utils } from "src/app/shared/utils";
import { PaginationResponseDTO } from "src/app/core/models/PaginationResponseDTO";
import { FileService } from "../../services/file.service";
import { HandleErrorService } from "../../services/handle-error.service";
import { MetersService } from "../../services/meters.service";
import { ModemDTO, SignalQuality } from "src/app/core/models/ModemDTO";
import { EnumMapperDTO } from "src/app/core/models/EnumMapperDTO";
import { TranslateService } from "@ngx-translate/core";
import { TranslateInCodeService } from "../../services/translate-in-code.service";

@Component({
  selector: "app-meter-table-not-comissioned",
  templateUrl: "./meter-table-not-comissioned.component.html",
  styleUrls: ["./meter-table-not-comissioned.component.scss"],
})
export class MeterTableNotComissionedComponent implements OnInit {
  @Input("data") items: MeterNotComissionedDTO[] = [];
  @Input() meter: MeterNotComissionedDTO;
  @Input() page = new PaginationResponseDTO();
  @Input() isLoading: boolean;
  @Input() isReloading: boolean;

  @Output() navigateToMeter = new EventEmitter();
  @Output() filterMeter = new EventEmitter();
  @Output() reload = new EventEmitter();

  public MeterPhase = MeterPhase;
  Utils = Utils;
  public MeterDTO = MeterDTO;
  public MeterNotComissioned = MeterNotComissionedDTO;
  Modem = ModemDTO;

  isLoadingAllMeters = false;
  public filledColor: string = '#fff';

  public startDate = "";
  public pageSize = 15;
  public endDate = "";
  public searchString = "";
  public sortOrder = "";
  public currentPage = 0;
  public placeHolderLabel =
    "Pesquisar pelo serial, código de instalação , EUI do modem ou Fabricante";

  private filter = {
    sortOrder: this.sortOrder,
    pageSize: this.pageSize,
    searchString: this.searchString,
    currentPage: this.currentPage,
    startDate: this.startDate,
    endDate: this.endDate,
    signal: '',
  };
  SiganlStrengthMapper: EnumMapperDTO;
  constructor(
    public fileService: FileService,
    private handleService: HandleErrorService,
    private meterNotComissionedService: MetersService,
    private modalService: NgbModal,
    private _translateServices: TranslateService,
    private _translateInCodeService: TranslateInCodeService,
  ) { }
  async ngOnInit() {
    await this.getTranslate();
    this._translateServices.onLangChange.subscribe(async () => await this.getTranslate());
  }

  async getTranslate() {
    this.SiganlStrengthMapper = await this._translateInCodeService.getTranslate('ModemInfo.SignalQuality', SignalQuality);
  }

  navigate(meter) {
    this.navigateToMeter.emit(meter);
  }

  updateSearchString(searchValue) {
    this.currentPage = 0;
    this.searchString = searchValue;
    this.filter["searchString"] = this.searchString;
    this.filter["currentPage"] = this.currentPage;
    this.emiteValue(this.filter);
  }

  sortByProp(prop) {
    const sort = prop.sorts[0];
    this.currentPage = 0;
    this.sortOrder = `${sort.prop}-${sort.dir}`;
    this.filter["currentPage"] = this.currentPage;
    this.filter["sortOrder"] = this.sortOrder;
    this.emiteValue(this.filter);
  }

  changePage(page) {
    this.currentPage = page.page - 1;
    this.filter["currentPage"] = this.currentPage;
    this.emiteValue(this.filter);
  }

  filterByDate() {
    this.filter["startDate"] = this.startDate;
    this.filter["endDate"] = this.endDate;
    this.currentPage = 0;
    this.filter["currentPage"] = this.currentPage;
    this.emiteValue(this.filter);
    this.closeModal();
  }

  finishOperation() {
    this.reseteDatas();
    this.closeModal();
  }

  private reseteDatas() {
    this.startDate = "";
    this.endDate = "";
  }

  openModal(content) {
    this.modalService.open(content, { centered: true });
  }

  private closeModal() {
    this.modalService.dismissAll();
  }

  private emiteValue(value) {
    this.filterMeter.emit(value);
  }

  public changeSizeValue(value) {
    this.pageSize = value;
    this.currentPage = 0;
    this.filter["pageSize"] = this.pageSize;
    this.filter["currentPage"] = this.currentPage;
    this.emiteValue(this.filter);
  }

  removeFilter() {
    this.reseteValues();
    this.emiteValue(this.filter);
    this.closeModal();
  }

  private reseteValues() {
    this.sortOrder = "";
    this.searchString = "";
    this.startDate = "";
    this.endDate = "";
    this.currentPage = 0;
    this.filter.signal = '';
    this.filledColor = '#fff';
    this.filter["currentPage"] = this.currentPage;
    this.filter["endDate"] = this.endDate;
    this.filter["startDate"] = this.startDate;
    this.filter["sortOrder"] = this.sortOrder;
    this.filter["searchString"] = this.searchString;
  }

  reloadMeters() {
    this.reload.emit();
  }

  changeSignal(signal) {
    this.filledColor = signal === null ? '#fff' : this.Modem.getSinalColor(signal);
    this.filter.signal = signal === null ? '' : signal;
    this.emiteValue(this.filter);
  }

  downloadAllNotComissionedMeters() {
    this.isLoadingAllMeters = true;
    this.meterNotComissionedService
      .getMetersNotComissionedWithoutPagination()
      .toPromise()
      .then((meters: MeterDTO[]) => {
        const rows = this.processMeters(meters);
        const workSheet: XLS.WorkSheet = XLS.utils.json_to_sheet([...rows]);
        workSheet["!cols"] = [{ width: 45 }];

        const workbook: XLS.WorkBook = {
          Sheets: {
            Medidores: workSheet,
          },
          SheetNames: ["Medidores"],
        };
        const excelBuffer: any = XLS.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        this.fileService.saveFileAsExcel(
          excelBuffer,
          "Todos os Medidores - Não Comissionados"
        );
        this.isLoadingAllMeters = false;
      })
      .catch((error) => {
        this.handleService.handle(error);
        this.isLoadingAllMeters = false;
      });
  }

  private processMeters(meters: MeterDTO[]) {
    let metersList = [];

    meters.forEach((_meter) => {
      let tags: string = "";
      let registers: string = "";
      _meter.tags.forEach((tag) => {
        tags += tag.keyword + ";";
      });
      if (tags.slice(tags.length - 1) === ";") {
        tags = tags.slice(0, tags.length - 1);
      }
      _meter.meterRegisters.forEach((_register) => {
        registers += _register.code + ";";
      });
      if (registers.slice(registers.length - 1) === ";")
        registers = registers.slice(0, registers.length - 1);

      const meter = {
        Fase: MeterDTO.getPhaseValue(_meter),
        Modelo: _meter.meterModel.name,
        Smc: _meter.smc !== null ? _meter.smc.serial : null,
        Serial: _meter.serial,
        Latitude: _meter.latitude,
        Longitude: _meter.longitude,
        Tags: tags,
        ["Comissionamento"]: _meter.comissioned
          ? "Comissionado"
          : "Não Comissionado",
        ['Estado de Relé']: MeterDTO.getRelayStatusValue(_meter.accountantStatus),
        ["Código de Instalação"]: _meter.installation,
        ["Estado de Comunicação"]: _meter.online ? "Online" : "Offline",
        DeviceEui: _meter.modem !== null ? _meter.modem.deviceEui : null,
        Fabricante:
          _meter.meterModel !== null
            ? _meter.meterModel.manufacturer.name
            : null,
        ['Versão de Firwmware']: _meter.firmwareVersion != null ? _meter.firmwareVersion.name : null,
        ICCID: _meter.modem !== null ? _meter.modem.iccid : null,
        IMEI: _meter.modem !== null ? _meter.modem.imei : null,
        PLMN: _meter.modem !== null ? _meter.modem.plmn : null,
        ['Período de MM']: _meter.templateCrcMassMemory,
        ['Visto por Último']: _meter.lastSeen
      };
      metersList.push(meter);
    });
    return metersList;
  }
}
