import { Component, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, OnInit } from "@angular/core";
import {
  MeterComissionedDTO,
  MeterDTO,
  MeterPhase,
} from "src/app/core/models/MeterDTO";
import { PaginationResponseDTO } from "src/app/core/models/PaginationResponseDTO";
import { Utils } from "src/app/shared/utils";
import { HandleErrorService } from "../../services/handle-error.service";
import { CommandsService } from '../../services/commands.service';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SpecialDayDTO } from "src/app/core/models/SpecialDayDTO";
import { SpecialDaysService } from "../../services/special-days.service";
import { MessageService } from "../../services/message.service";

@Component({
  selector: "special-days-table",
  templateUrl: "./special-days-table.component.html",
  styleUrls: ["./special-days-table.component.scss"],
})
export class SpecialDaysTableComponent implements OnChanges, OnInit {
  @Input("data") items: SpecialDayDTO[] = [];
  @Input() meter: MeterComissionedDTO;
  @Input() page: PaginationResponseDTO;
  @Input() showView = true;
  @Input() isLoading = false;
  @Input() isReloading = false;
  @Input() tickets = [];

  @Output() navigateToMeter = new EventEmitter();

  allDaylightSaving: SpecialDayDTO[] = [];

  public MeterPhase = MeterPhase;
  public MeterDTO = MeterDTO;
  public Utils = Utils;
  public MeterComissioned = MeterComissionedDTO;

  public startDate = "";
  public endDate = "";
  public searchString = "";
  public sortOrder = "updatedAt-desc";
  public currentPage = 0;
  public pageSize = 15;
  public placeHolderLabel =
    "Pesquisar pelo serial, código de instalação , EUI do modem ou Fabricante";
  public filledColor: string = '#fff';

  @ViewChild('ngxTable', { static: false }) table: any;
  isEdit = false;
  toUpdate: SpecialDayDTO = {};
  allToUpdate: SpecialDayDTO[] = [];
  loadingCommands: boolean = false;
  toAdd = [];
  toDelete = [];
  isValidFormat?: boolean = false;

  constructor(
    private readonly _commandService: CommandsService,
    private readonly _specialDaysService: SpecialDaysService,
    private readonly _handleErrorService: HandleErrorService,
    private readonly _ngbModal: NgbModal,
    private readonly _messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.getAllDaylightSaving()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.meter.currentValue != null)
      this.getDaylightSaving()
  }

  selectToUpdate(row: SpecialDayDTO) {
    this.toUpdate = row;
  }

  removeRow(row: SpecialDayDTO, i) {
    var exists = this.items.find((_, index) => index == i && _.meterId != null);
    if(exists)
      this.toDelete.push(exists);

    this.items = this.items.filter((_, index) => index != i);
    this.toAdd = this.toAdd.filter((item) => item.specialDayDate !== row.specialDayDate);

  }

  notSend(row: SpecialDayDTO, i) {
    this.toDelete = this.toDelete.filter((item, index) => row.specialDayDate != item.specialDayDate);
    this.items.unshift(row)
    this.items = [...this.items]
  }

  finishOperation(clear = false) {
    this._ngbModal.dismissAll();
  }

  addAndClean() {
    if(!Object.values(this.toUpdate).length) return;
    this.items.unshift(this.toUpdate);
    this.items = [...this.items]
    this.toAdd.push(this.toUpdate);
    this.toUpdate = {};
  }

  async sendCommands() {
    try {
      this.loadingCommands = true;
      this.addAndClean()
      this.toAdd.forEach(item => {
        item.specialDayDate = item.specialDayDate.split('/').reverse().join('/')
        return item;
      });
      await this._commandService.setSpecialDaysTable(this.meter.serial, this.items).toPromise();
      this._messageService.showSuccess('Comando', 'Comando enviado com sucesso!');
      this.loadingCommands = false;
      this.toAdd = [];
      this.finishOperation();
    } catch (error) {
      this.loadingCommands = false;
      this._handleErrorService.handle(error);
    }
  }

  onInputChange(_) {
    this.toUpdate.specialDayDate = this.toUpdate.specialDayDate.replace(/[^0-9a-zA-Z/]/g, '');

    if (this.toUpdate.specialDayDate.length === 2 || this.toUpdate.specialDayDate.length === 5) {
      this.toUpdate.specialDayDate += '/';
    }

    const dateRegex = /^([0-9a-zA-Z/]{2})\/([0-9a-zA-Z/]{2})\/([0-9a-zA-Z/]{4})$/;
    this.isValidFormat = dateRegex.test(this.toUpdate.specialDayDate);
  }

  async sendDelete() {
    try {
      this.loadingCommands = true;
      this.toDelete.forEach(item => {
        item.specialDayDate = item.specialDayDate.split('/').reverse().join('/')
        return item;
      });
      console.log(this.toDelete);
      await this._commandService.deleteSpecialDaysTable(this.meter.serial, this.items).toPromise();
      this._messageService.showSuccess('Comando', 'Comando enviado com sucesso!');
      this.finishOperation();
      this.loadingCommands = false;
      this.toDelete = [];
    } catch (error) {
      this.loadingCommands = false;
      this._handleErrorService.handle(error);
    }
  }

  async getCommands() {
    try {
      this.loadingCommands = true;
      await this._commandService.getSpecialDaysTable(this.meter.serial).toPromise(),
      this.loadingCommands = false;
    } catch (error) {
      this.loadingCommands = false;
      this._handleErrorService.handle(error)
    }

  }

  openModal(content, toUpdate: SpecialDayDTO = {}) {
    this.toUpdate = toUpdate;
    this._ngbModal.open(content, { centered: true });
  }

  getDaylightSaving() {
    
    this._specialDaysService.getAll(this.meter.serial)
      .toPromise()
      .then(res => {
        this.items = res.listOfItems;
        //if(!this.items.length)
          //this.items.push({dayId: 1, index: 1, specialDayDate: 'FF'})
      }).catch(error => {
        //this._handleErrorService.handle(error);
        console.log(error)

      })
  }

  getAllDaylightSaving() {
    //this.allDaylightSaving.push({ deviation: 1, endDate: new Date(), startDate: new Date, isEnable: false });

  /*this._specialDaysService.getAll()
      .toPromise()
      .then(res => {
        this.allDaylightSaving = res;

      }).catch(error => {
        //this._handleErrorService.handle(error);
        console.log(error)
      
      })*/
  }

  navigate(serial) {
    this.navigateToMeter.emit(serial);
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }
}
