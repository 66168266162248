import { Component, Input, Output, EventEmitter, AfterViewInit, OnChanges, SimpleChanges } from "@angular/core";
import { NgbModal, NgbTimeStruct } from "@ng-bootstrap/ng-bootstrap";
import { FormControl } from "@angular/forms";
import { Utils } from "src/app/shared/utils";
import * as moment from "moment";
import { UserType } from "src/app/core/models/UserDTO";
import { AuthService } from "src/app/security/auth.service";
import { MeterModelDTO } from "src/app/core/models/MeterModelDTO";
import { FirmwareDTO } from "src/app/core/models/FirmwareDTO";

const validStarMeasureUplinkPeriods: number[] = [1, 5, 15];
const validUplinksPeriods: number[] = [
  1, 5, 10, 15, 20, 30, 60, 120, 240, 360, 480, 720, 1440,
];
const validEletraUplinkPeriods: number[] = [
  15, 30, 60, 120, 240, 360, 480, 720, 1440,
];
@Component({
  selector: "app-change-period",
  templateUrl: "./change-period.component.html",
  styleUrls: ["./change-period.component.scss"],
})
export class ChangePeriodComponent implements AfterViewInit, OnChanges {
  @Input() serialMedidor: string;
  @Input() model: MeterModelDTO;
  @Input() firmware: FirmwareDTO;
  @Input() isChanging = false;
  @Input() isLoading = true;
  @Output() changePeriod = new EventEmitter();
  @Input() currentTimeInMinutes: number = null;
  Utils = Utils;

  modalRef: any = null;
  isTwentyFoursHours = false;

  public canChangePeriod = [UserType.ADMIN];

  seconds = false;
  isValid = false;
  minuteStep = 30;
  hourStep = 1;
  periodTime: NgbTimeStruct = { hour: 0, minute: 0, second: 0 };
  periodMinutesSteps = 10;
  periodHoursSteps = 1;
  time: NgbTimeStruct = { hour: 0, minute: 0, second: 0 };
  minDate = {
    year: new Date(moment.now()).getFullYear(),
    month: new Date(moment.now()).getMonth() + 1,
    day: new Date(moment.now()).getDate(),
  };
  maxDate = {
    year: new Date(moment.now()).getFullYear() + 1,
    month: new Date(moment.now()).getMonth() + 1,
    day: new Date(moment.now()).getDate(),
  };

  TimeForm = new FormControl("", (control: FormControl) => {
    const value = control.value;
    if (!value) {
      return { valid: true };
    }

    if (value.minute < 10 && value.hour === 0) {
      return { min: true };
    }
    return null;
  });

  constructor(private modalService: NgbModal, public authService: AuthService) { }

  ngAfterViewInit(): void {
    this.setMinuteStep();
    this.modelChange();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.model || changes.firmware) {
      this.setMinuteStep();
      this.modelChange();
    }
  }

  openModal(modal: any) {
    this.modalRef = this.modalService.open(modal, { centered: true });
  }

  closeModal() {
    this.modalRef.close();
  }

  closeAll() {
    this.modalService.dismissAll();
  }

  changePeriodo() {
    this.currentTimeInMinutes = this.isTwentyFoursHours
      ? 24 * 60
      : this.periodTime.hour * 60 + this.periodTime.minute;
    this.changePeriod.emit({
      time: this.currentTimeInMinutes,
      timeToStart: this.time,
    });
    this.closeModal();
  }

  modelChange() {
    if (this.periodTime.hour === 0) {
      this.setMinuteStep();
    }
  
    const isEletraOrAbsolute = this.model && (this.model.manufacturer.name === "ELETRA" || this.model.name === "ABSOLUTE");
    const isStarMeasureEasyTrafo = this.model && this.model.manufacturer.name === "STAR MEASURE" && this.model.name === "EASY TRAFO";
    const isFirmwareEasyTrafoV29Above = this.firmware && parseFloat(this.firmware.name) >= 2.9;
  
    console.log(`isEletraOrAbsolute: ${isEletraOrAbsolute}`);
    console.log(`isStarMeasureEasyTrafo: ${isStarMeasureEasyTrafo}`);
    console.log(`isFirmwareEasyTrafoV29Above: ${isFirmwareEasyTrafoV29Above}`);
  
    if (isEletraOrAbsolute) {
      this.isValid = validEletraUplinkPeriods.includes(
        this.isTwentyFoursHours ? 1440 : this.periodTime.hour * 60 + this.periodTime.minute
      );
    } else if (isStarMeasureEasyTrafo) {
      if (isFirmwareEasyTrafoV29Above) {
        this.isValid = validStarMeasureUplinkPeriods.includes(
          this.isTwentyFoursHours ? 1440 : this.periodTime.hour * 60 + this.periodTime.minute
        );
      } else {
        this.isValid = validUplinksPeriods.includes(
          this.isTwentyFoursHours ? 1440 : this.periodTime.hour * 60 + this.periodTime.minute
        );
      }
    } else {
      this.isValid = validUplinksPeriods.includes(
        this.isTwentyFoursHours ? 1440 : this.periodTime.hour * 60 + this.periodTime.minute
      );
    }
  
    console.log(`this.isValid: ${this.isValid}`);
  }

  private setMinuteStep() {
    if (!this.model || !this.firmware) {
      return;
    }

    const isEletraOrAbsolute = this.model.manufacturer.name === "ELETRA" || this.model.name === "ABSOLUTE";
    const isStarMeasureEasyTrafo = this.model.manufacturer.name === "STAR MEASURE" && this.model.name === "EASY TRAFO";
    const isFirmwareEasyTrafoV29Above = parseFloat(this.firmware.name) >= 2.9;

    console.log(`isEletraOrAbsolute: ${isEletraOrAbsolute}`);
    console.log(`isStarMeasureEasyTrafo: ${isStarMeasureEasyTrafo}`);
    console.log(`isFirmwareEasyTrafoV29Above: ${isFirmwareEasyTrafoV29Above}`);

    if (isEletraOrAbsolute) {
      this.periodMinutesSteps = 15;
    } else if (isStarMeasureEasyTrafo) {
      if (isFirmwareEasyTrafoV29Above) {
        this.periodMinutesSteps = 1;
        this.periodTime.minute = this.periodTime.minute === 0 ? 1 : this.periodTime.minute;
      } else {
        this.periodMinutesSteps = 10;
      }
    } else {
      this.periodMinutesSteps = 10;
    }

    console.log(`this.periodMinutesSteps: ${this.periodMinutesSteps}`);
  }
};
