import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SmcDTO, SmcManufacturer } from '../../../core/models/SmcDTO';
import { MessageService } from '../../services/message.service';
import { NgForm } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HandleErrorService } from '../../services/handle-error.service';
import { UserType } from '../../../core/models/UserDTO';
import { MeterDTO, MeterPhase } from '../../../core/models/MeterDTO';
import { Utils } from 'src/app/shared/utils';
import { SmcModelDTO } from 'src/app/core/models/SmcModelDTO';
import { SmcsService } from '../../services/smcs.service';

@Component({
    selector: 'app-smc-register-table',
    templateUrl: './smc-table-register.component.html',
    styleUrls: ['./smc-table-register.component.scss']
})
export class SmcRegisterTableComponent implements OnInit {

    @Input() data: SmcDTO[];
    @Input() isLoading = true;

    @Output() filter = new EventEmitter();
    @Output() reload = new EventEmitter();


    SmcDTO = SmcDTO;
    Utils = Utils;
    SmcManufacturer = SmcManufacturer;
    SmcModels: SmcModelDTO[] = [];
    allModels: SmcModelDTO[] = [];
    MeterPhase = MeterPhase;

    smc: SmcDTO;
    deletedSmc: SmcDTO;
    deletedMeterSmc: MeterDTO;

    isloading = false;
    isEditing = false;

    hardOperations = [UserType.ADMIN, UserType.OPERATOR];

    currentModal: NgbModalRef;

    constructor(
        private smcService: SmcsService,
        private messageService: MessageService,
        private modalService: NgbModal,
        private handleService: HandleErrorService,
    ) {
    }

    ngOnInit() {
        this.smc = new SmcDTO();
        this.deletedSmc = new SmcDTO();
        this.getSmcModels();
    }

    modalSmc(content, row?: SmcDTO) {
        if (this.isEditing && row) {
            this.smcService.getSmcsBySerial(row.serial).toPromise().then((data) => {
                this.smc = (<SmcDTO>data);
                this.currentModal = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
            }).catch(err => {
                this.handleService.handle(err);
            });
            return;
        }
        this.smc.smcModel =
            this.allModels.find(_model => _model.name.toUpperCase() === 'PANTHEON') || this.smc.smcModel;
        this.currentModal = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
    }

    changeModel(event) {
        const Models = this.allModels;
        const modelName = event.target.value;
        const selectedModel = Models.find(model => model.name === modelName);
        this.smc.smcModel = selectedModel;

    }

    search(searchString: string): void {
        this.filter.emit(searchString);
    }

    getSmcModels() {
        this.smcService.getSmcModels().toPromise()
            .then((models: SmcModelDTO[]) => {
                this.SmcModels = models;
                this.allModels = models;
            });
    }

    commitModalSmc(form: NgForm) {
        this.isloading = true;
        if (!this.isEditing) {
            this.smc.serial = String(this.smc.serial).trim();
            this.smcService.saveSmc(this.smc).toPromise()
                .then(
                    () => {
                        this.finishOperation(form);
                        this.messageService.showSuccess('Operação Concluída', 'SMC cadastrado com sucesso');
                        this.isloading = false;
                    }
                ).catch(err => {
                    this.isloading = false;
                    this.handleService.handle(err);
                });
        } else {
            this.smcService.updateSmc(this.smc).toPromise()
                .then(
                    () => {
                        this.finishOperation(form);
                        this.messageService.showSuccess('Operação Concluída', 'SMC modificado com sucesso');
                        this.isloading = false;
                    }
                ).catch(err => {
                    this.isloading = false;
                    this.handleService.handle(err);
                });
        }
    }

    confirmSmcExclusion(content, row) {
        this.deletedSmc = (<SmcDTO>row);
        this.currentModal = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true });
    }


    deleteSmc() {
        this.isloading = true;
        this.smcService.deleteSmc(this.deletedSmc.serial).toPromise()
            .then(() => {
                this.finishOperation();
                this.messageService.showSuccess('Operação Concluída', 'SMC excluído com sucesso!');
            }).catch(err => {
                this.finishOperation();
                this.handleService.handle(err);
            });
    }

    finishOperation(form?: NgForm) {
        this.smc = new SmcDTO();
        this.modalService.dismissAll();
        this.isloading = false;
        this.isEditing = false;
        if (form) {
            form.reset();
        }
    }

    reloadSmc() {
        this.reload.emit();
    }

}



