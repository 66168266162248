
export interface IWeekTariff {
    name?: string;
    serial?: string;
    mondayId?: number;
    tuesdayId?: number;
    wednesdayId?: number;
    thursdayId?: number;
    fridayId?: number;
    saturdayId?: number;
    sundayId?: number;
}

export enum TariffType
{
    PONTA = 1,
    FORA_PONTA = 2,
    RESERVADO = 3,
    QUARTO_POSTO = 4
}

export interface IHourlyTariff {
    time?: string;
    tariffType: TariffType;
}

export interface IDailyTariff {
    name?: string;
    description?: string;
    id?: string;
    dayId?: number;
    hourlyTariffs?: IHourlyTariff[];
}

export interface IDailyTariffTemplate {
    name?: string;
    description?: string;
    hourlyTariffs?: IHourlyTariff[];
}

export interface IMeterProfile {
    name?: string;
    serial?: string;
    dailyTariffs: IDailyTariff[];
    id?: string;
}

export interface IActiveCalendar {
    serial?: string;
    status?: boolean;
    date?: Date;
    meterProfile?: IMeterProfile;
    id?: string;
    meterProfileId?: string;
}

export const getTariffTypeLabel = (type: TariffType) => {
    switch(type) {
        case TariffType.FORA_PONTA:
            return 'Fora ponta';
        case TariffType.PONTA:
            return 'Ponta';
        case TariffType.QUARTO_POSTO:
            return 'Quarto posto';
        case TariffType.RESERVADO:
            return 'Reservado'
    }
}