import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Utils } from "src/app/shared/utils";
import { AlarmDTO } from "src/app/core/models/AlarmsDTO";
import { PaginationResponseDTO } from "src/app/core/models/PaginationResponseDTO";
import { FileService } from "../../services/file.service";
import { ExportsDataServices } from 'src/app/shared/services/export-data.service';
import { HandleErrorService } from 'src/app/shared/services/handle-error.service';

@Component({
  selector: "app-alarm-table",
  templateUrl: "./alarm-table.component.html",
  styleUrls: ["./alarm-table.component.scss"],
})
export class AlarmTableComponent {
  @ViewChild("alarmTable", { static: false }) alarmTable: DatatableComponent;

  @Input() alarms: AlarmDTO[];
  @Input() showMeter = true;
  @Input() showFase = true;
  @Input() page: PaginationResponseDTO;
  @Input() isLoading = true;
  @Input() isReloading = false;
  @Input() serial: string = null;

  @Output() filterByDate = new EventEmitter();
  @Output() getCurrentPage = new EventEmitter();
  @Output() reload = new EventEmitter();

  AlarmDTO = AlarmDTO;
  Utils = Utils;

  public startDate = "";
  public endDate = "";
  public sortProp = "updatedAt";
  public sortOrder = "desc";
  public sortBy = "";
  public searchString = "";
  public description = "";
  public pageSize = 15;
  public currentPage = 0;
  public dates = {
    page: this.currentPage,
    begin: this.startDate,
    pageSize: this.pageSize,
    end: this.endDate,
    sortOrder: this.sortOrder,
    sortProp: this.sortProp,
    sortBy: this.sortBy,
    searchString: this.searchString,
  };

  public isDownloadingAlarms: boolean = false;

  isLoadingAllAlarms = false;

  constructor(
    private modalService: NgbModal,
    private fileService: FileService,
    private exportFilesServices: ExportsDataServices,
    private erroHandlerServices: HandleErrorService
  ) { }

  openModal(content) {
    this.modalService.open(content, { centered: true });
  }

  close() {
    this.modalService.dismissAll();
  }

  filterAlarm(page) {
    this.currentPage = page.page - 1;
    this.dates["page"] = this.currentPage;
    this.emiteValue(this.dates);
  }

  filterBydate() {
    this.currentPage = 0;
    this.dates["page"] = this.currentPage;
    this.dates.end = this.endDate;
    this.dates.begin = this.startDate;
    this.emiteValue(this.dates);
  }
  upadateSearchString(value: string) {
    this.searchString = value;
    this.dates.searchString = this.searchString;
    this.emiteValue(this.dates);
  }

  finishOperation() {
    this.close();
    this.resetValues();
  }

  resetValues() {
    this.startDate = "";
    this.endDate = "";
  }

  changeSizeValue(value) {
    this.pageSize = value;
    this.currentPage = 0;
    this.dates["page"] = this.currentPage;
    this.dates["pageSize"] = this.pageSize;
    this.emiteValue(this.dates);
  }

  onSort(value) {
    const { sorts } = value;
    this.sortProp = sorts[0].prop;
    this.sortOrder = sorts[0].dir;
    this.dates["sortProp"] = this.sortProp;
    this.dates["sortOrd"] = this.sortOrder;
    this.currentPage = 0;
    this.dates["page"] = this.currentPage;
    this.emiteValue(this.dates);
  }

  private emiteValue(value) {
    this.filterByDate.emit(value);
  }

  public removeFilter() {
    this.sortProp = "";
    this.sortOrder = "";
    this.startDate = "";
    this.endDate = "";
    this.pageSize = 15;
    this.currentPage = 0;
    this.searchString = "";
    this.dates.searchString = this.searchString;
    this.dates["page"] = this.currentPage;
    this.dates["end"] = this.endDate;
    this.dates["begin"] = this.startDate;
    this.dates["sortOrd"] = this.sortOrder;
    this.dates["sortProp"] = this.sortProp;
    this.dates["pageSize"] = this.pageSize;
    this.emiteValue(this.dates);
  }

  download() {
    this.isDownloadingAlarms = true;

    const sortBy = this.sortBy || '';
    const sortOrder = this.sortOrder || '';
    const endDate = this.endDate || '';
    const startDate = this.startDate || '';
    const serialMeter = this.serial || '';
    const description = this.description || '';

    this.exportFilesServices.exportMeterAlarms(sortBy, sortOrder, endDate, startDate, serialMeter, description)
      .toPromise()
      .then((response) => {
        const dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        downloadLink.setAttribute('download', 'Alarmes_Medidores.csv');
        document.body.appendChild(downloadLink);
        downloadLink.click();
        this.isDownloadingAlarms = false;
      })
      .catch(error => {
        this.erroHandlerServices.handle(error);
        this.isDownloadingAlarms = false;
      });
  }

  reloadAlarms() {
    this.reload.emit();
  }
}
