import * as moment from 'moment';
moment.locale('pt-br');

export interface ICommandHubDTO {
    serial: string;
    updatedAt: Date;
}

export class CommandHubDTO implements ICommandHubDTO {
    public serial: string;
    public updatedAt: Date;

    static formatDate(date: Date): Date {
        const now = new Date();
        const offsetInMinutes = now.getTimezoneOffset();
        const offSetInHours = (offsetInMinutes / 60);
        const stringDate = moment(date).subtract(offSetInHours, 'hours').toString();
        return new Date(stringDate);
    }

    static formateDateUtc(date: Date): Date {
        const stringDate = moment(date).utc().toString();
        return new Date(stringDate);
    }
}

export enum CommandHubType {
    commandQueueWasUpdatedEvent = 0,
    commandStatusWasUpdatedEvent,
    comunicationStatusWasUpdatedEvent,
    relayStateWasChangedEvent,
    alarmWasGeneratedEvent,
    logWasGeneratedEvent,
    ticketWasGeneratedEvent,
    doorStatewasUpdatedEvent,
    ATicketWasUpdatedEvent,
    ASmiUplinkIntervalUpdatedEvent,
    ASmcUplinkIntervalUpdatedEvent,
    AProcessWasCreatedEvent,
    AProcessWasUpdatedEvent,
    AMeterWasAddedToASmcEvent = 13,
    MetersFromSmcWereUpdatedEvent = 14,
    AMeterWasRemovedFromASmcEvent = 15,
    AModemWasUpdatedEvent = 16,
    ANetworkTypeWasUpdatedEvent = 17,
    ANetworkTechWasUpdatedEvent = 18,
    ACellIdAndIPWereUpdatedEvent = 19
}
