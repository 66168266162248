export interface IMeterDMCRProfileDTO {
    id?: string;
    name?: string;
    serial?: string;
    dailyDMCRs?: IDailyDMCRDTO[];
}

export interface IDailyDMCRDTO {
    id?: string;
    dayId?: number;
    meterDMCRProfileId?: string;
    hourlyDMCRs?: IHourlyDMCR[];
}

export enum IDMCRType {
    INDUTIVO = 1,
    CAPACITIVO = 2
}

export interface IHourlyDMCR {
    id?: string;
    time?: string;
    dmcrType?: IDMCRType;
}

const labels = {
    1: 'Indutivo',
    2: 'Capacitivo'
}

export const getDmcrTypeLabel = (type: IDMCRType) => {
    return labels[type] || 'Desconhecido';
}