import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatSelect } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { IDropdownSettings } from "ng-multiselect-dropdown/multiselect.model";
import { AccountStatus, IMeterDTO, MeterDTO } from "src/app/core/models/MeterDTO";
import { PaginationResponseDTO } from "src/app/core/models/PaginationResponseDTO";
import { CommandType, TicketDTO, TicketsStatus } from "src/app/core/models/TicketsDTO";
import { CommandHubDTO, CommandHubType } from "src/app/core/models/command-event/CommandHubDTO";
import { echartStyles } from "src/app/shared/components/echart/echart.component";
import { HubService } from "src/app/shared/services/hub.service";
import { LocalStoreService } from "src/app/shared/services/local-store.service";
import { MetersService } from "src/app/shared/services/meters.service";
import { environment } from "src/environments/environment";

export enum VERTICAL_NAV {
  clock,
  SpecialDays,
  ActivityCalendar,
  EndBillingPeriod,
  DmcrAndUferSwitch,
  MeterDmcrProfile,
  Registers,
  GeneralReadings
}

interface NavItem {
    displayName: string;
    disabled?: boolean;
    iconName: string;
    route?: number;
    children?: NavItem[];
    expanded?: boolean;
    activated?: boolean;
}
@Component({
    selector: 'app-parameterize',
    templateUrl: './parameterize.component.html',
    styleUrls: ['./parameterize.component.scss']
})
export class ParametrizeComponent implements OnInit, OnDestroy {

    constructor(private readonly _meterServices: MetersService, private hubService: HubService,
      private readonly _ls: LocalStoreService, private route: ActivatedRoute
    ) {
      this.route.queryParams.subscribe(queryParams => {
        this.commandType = queryParams['commandType']
        console.log(this.commandType)
      })
    }

    ticketsPage = new PaginationResponseDTO()
    meterPage = new PaginationResponseDTO();
    isLoadingMeters: boolean = true;
    progressChartOptions;
    CommandHUbDTO = CommandHubDTO;
    public connection: signalR.HubConnection;
    iWantToCloseWs = false;
    commandType: CommandType;
    @ViewChild('select', {static: false}) select: MatSelect;
    isLoading: boolean = false;

    meters: any[] = []
    selectedMeters: IMeterDTO[] = [];
    selectedSerials: string[] = [];
    meterDropDownSettings: IDropdownSettings = {
        singleSelection: false,
        idField: 'serial',
        textField: 'serial',
        allowSearchFilter: true,
        noDataAvailablePlaceholderText: '',
        searchPlaceholderText: 'Pesquise por serial',
        selectAllText: 'Selecionar todos',
        unSelectAllText: 'Remover todos',
        enableCheckAll: false,
    };

    menu: NavItem[] = [
        {
            displayName: 'Relacionados ao Tempo',
            iconName: 'av_timer',
            expanded: true,
            children: [{
                displayName: 'Relógio',
                iconName: 'access_time',
                route: VERTICAL_NAV.clock,
            },
            {
                displayName: 'Feriados',
                iconName: 'calendar_today',
                route: VERTICAL_NAV.SpecialDays,
            },
            {
              displayName: 'Tarifa',
              iconName: 'calendar_today',
              route: VERTICAL_NAV.ActivityCalendar,
          }]
        },
        {
          displayName: 'Relacionados a Eletricidade',
          iconName: 'av_timer',
          expanded: false,
          children: [{
              displayName: 'Horário indutivo e capacitivo',
              iconName: 'access_time',
              route: VERTICAL_NAV.MeterDmcrProfile,
          },
          {
            displayName: 'Switch DMRC e UFER',
            iconName: 'bolt',
            route: VERTICAL_NAV.DmcrAndUferSwitch,
          }]
        },
        {
          displayName: 'Demandas',
          iconName: 'av_timer',
          expanded: false,
          children: [
          {
            displayName: 'Registradores',
            iconName: 'app_registration',
            route: VERTICAL_NAV.Registers,
          },
          {
            displayName: 'Leituras gerais',
            iconName: 'library_books',
            route: VERTICAL_NAV.GeneralReadings,
          }]
        },
    ]

    meterListEle: Element;


    async openConnection() {
      this.connection = await this.hubService.connect(
        'generalHub',
        'UpdateForAll'
      );
      this.refreshTable();
      this.connection.onclose(async () => {
        !this.iWantToCloseWs && this.openConnection();
      });
    }

    ngOnDestroy() {
      this.iWantToCloseWs = true;
      this.hubService.disconnect(this.connection);
      this.connection = null;
      if(this.meterListEle)
        this.meterListEle.removeEventListener('scroll', () => this.onScroll(this.meterListEle), true)

    }

    getChart() {
        this.progressChartOptions = {
            ...echartStyles, ...{
              tooltip: {
                triggerOn: 'mousemove',
                trigger: 'item'
              },
              legend: {
                orient: 'vertical',
                left: 'left',
                data: ['Finalizados', 'Executando', 'Falhas']
              },
              series: [
                {
                  name: 'Estados dos Tickets',
                  type: "pie",
                  data: [
                    {
                      name: 'Finalizados',
                      value: 10,
    
                      itemStyle: {
                        color: '#4ae41c'
                      }
                    },
                    {
                      name: 'Executando',
                      value: 80,
                      itemStyle: {
                        color: '#07467f'
                      }
                    },
                    {
                      name: 'Falhas',
                      value: 10,
                      itemStyle: {
                        color: '#da0404'
                      }
                    }
                  ],
                  emphasis: {
                    itemStyle: {
                      shadowBlur: 10,
                    }
                  }
                },
              ],
            }
          }
    }

    async ngOnInit() {
        this.meterPage.pageSize = 25;
        this.getMeters()
        this.getChart()
        await this.openConnection()
        
        this.meterListEle = document.querySelector('.dropdown-list ul:nth-child(2)');
        this.meterListEle.addEventListener('scroll', () => this.onScroll(this.meterListEle))
        //this.onScroll(this.meterListEle)
    }

    setMeterId(id: string) {
        console.log(this.selectedMeters)
        this.selectedMeters = this.meters.filter(m => this.selectedSerials.includes(m.serial));
    }

    onDeSelect(e) {
      this.selectedMeters = this.meters.filter(m => this.selectedSerials.includes(m.serial));
    }

    onItemDeSelect(e) {
      this.selectedMeters = [];
    }

    onSelectAll(event) {
      console.log(event)
      this.selectedMeters = this.meters.filter(m => event.includes(m.serial));
      console.log(this.selectedMeters)

    }

    getMeters() {
        this.isLoadingMeters = true;
        this._meterServices.getMetersMinimal('updatedAt-desc', '', this.meterPage.page == 0 ? 1 : this.meterPage.page + 1, 15)
            .toPromise()
            .then((res: PaginationResponseDTO) => {
                this.isLoadingMeters = false;
                this.meters = [...this.meters, ...res.listOfItems]
                this.meterPage.page = res.page;
            })
            .catch(error =>{ 
                console.log(error)
                this.isLoadingMeters = false;
            });
    }

    onScroll(selectElement: Element) {
      const atBottom = selectElement.scrollTop + selectElement.clientHeight >= selectElement.scrollHeight - 20;
      if (atBottom && !this.isLoadingMeters) {
        this.getMeters();
      }
    }

    refreshTable() {
      this.connection.on('UpdateForAll', (obj) => {
        obj = JSON.parse(obj);
        const commandType: CommandHubType = obj['type'];      

        obj['payload'].updatedAt = this.CommandHUbDTO.formatDate(
          obj['payload'].updatedAt
        );
        
        if(!this.meters.map(m => m.serial).includes(obj['payload'].serial)) return;
        
        switch (commandType) {
          case CommandHubType.ticketWasGeneratedEvent:
            const generetedTicket: TicketDTO = obj['payload'].ticket;

            generetedTicket.ticketCreationDate = this.CommandHUbDTO.formatDate(
              generetedTicket.ticketCreationDate
            );
            generetedTicket.updatedAt = this.CommandHUbDTO.formatDate(
              generetedTicket.updatedAt
            );
            generetedTicket.initialDate = this.CommandHUbDTO.formatDate(
              generetedTicket.initialDate
            );
            generetedTicket.finishDate = this.CommandHUbDTO.formatDate(
              generetedTicket.finishDate
            );
            generetedTicket.commandTickets.forEach((command) => {
              command.initialDate = this.CommandHUbDTO.formatDate(
                command.initialDate
              );
              command.finishDate = this.CommandHUbDTO.formatDate(
                command.finishDate
              );
            });
            const index = this.ticketsPage.listOfItems.findIndex(t => t.ticketId == generetedTicket.ticketId);
            if(index != -1)
              this.ticketsPage.listOfItems[index] = generetedTicket;
            else {
              if(this.ticketsPage.listOfItems.length >= this.ticketsPage.pageSize) 
                this.ticketsPage.listOfItems.pop()

              this.ticketsPage.listOfItems.unshift(generetedTicket)
            }
            this.ticketsPage.listOfItems = [...this.ticketsPage.listOfItems]
            this.ticketsPage.numberOfItems = this.ticketsPage.listOfItems.length
            break;
          case CommandHubType.ATicketWasUpdatedEvent:
            const updatedTicket: TicketDTO = obj['payload'].ticket;
            updatedTicket.ticketCreationDate = this.CommandHUbDTO.formatDate(
              updatedTicket.ticketCreationDate
            );
            updatedTicket.updatedAt = this.CommandHUbDTO.formatDate(
              updatedTicket.updatedAt
            );
            updatedTicket.initialDate = this.CommandHUbDTO.formatDate(
              updatedTicket.initialDate
            );
            updatedTicket.finishDate = this.CommandHUbDTO.formatDate(
              updatedTicket.finishDate
            );
            updatedTicket.commandTickets.forEach((command) => {
              command.initialDate = this.CommandHUbDTO.formatDate(
                command.initialDate
              );
              command.finishDate = this.CommandHUbDTO.formatDate(
                command.finishDate
              );
            });
           // console.log(this.ticketsPage)
            const ticketIndex = this.ticketsPage.listOfItems.findIndex(t => t.ticketId == updatedTicket.ticketId)
            //console.log(ticketIndex)

            if(ticketIndex != -1)
              this.ticketsPage.listOfItems[ticketIndex] = updatedTicket;
            else {
              if(this.ticketsPage.listOfItems.length >= this.ticketsPage.pageSize) 
                this.ticketsPage.listOfItems.pop()

              this.ticketsPage.listOfItems.push(updatedTicket)
            }
            this.ticketsPage.listOfItems = [...this.ticketsPage.listOfItems]
            this.ticketsPage.numberOfItems = this.ticketsPage.listOfItems.length
            break;
          }
      });
  
    }  
}