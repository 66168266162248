import { Component, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, OnInit } from "@angular/core";
import * as XLS from "xlsx";
import {
  MeterComissionedDTO,
  MeterDTO,
  MeterPhase,
} from "src/app/core/models/MeterDTO";
import { PaginationResponseDTO } from "src/app/core/models/PaginationResponseDTO";
import { Utils } from "src/app/shared/utils";
import { HandleErrorService } from "../../services/handle-error.service";
import { CommandsService } from '../../services/commands.service';
import { DaylightSavingService } from '../../services/daylight-saving.service';
import { DaylightSavingDto } from "src/app/core/models/DaylightSaving";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DmcrSwitchService } from "../../services/dmcr.service";
import { IDmcrSwitch } from "src/app/core/models/DmcrSwitchDTO";

@Component({
  selector: "app-dmcr-switch-table",
  templateUrl: "./dmcr-switch-table.component.html",
  styleUrls: ["./dmcr-switch-table.component.scss"],
})
export class DmcrSwitchTableComponent implements OnChanges {
  @Input("data") items: IDmcrSwitch[] = [];
  @Input() meter: MeterComissionedDTO;
  @Input() page: PaginationResponseDTO;
  @Input() showView = true;
  @Input() isLoading = false;
  @Input() isReloading = false;
  @Input() tickets = [];

  @Output() navigateToMeter = new EventEmitter();

  allDaylightSaving: DaylightSavingDto[] = [];

  public MeterPhase = MeterPhase;
  public MeterDTO = MeterDTO;
  public Utils = Utils;
  public MeterComissioned = MeterComissionedDTO;

  public startDate = "";
  public endDate = "";
  public searchString = "";
  public sortOrder = "updatedAt-desc";
  public currentPage = 0;
  public pageSize = 15;
  public placeHolderLabel =
    "Pesquisar pelo serial, código de instalação , EUI do modem ou Fabricante";
  public filledColor: string = '#fff';

  @ViewChild('ngxTable', { static: false }) table: any;
  isEdit = false;
  toUpdate: DaylightSavingDto = null;
  loadingCommands: boolean = false;
  constructor(
    private readonly _commandService: CommandsService,
    private readonly _dmcrSwitchService: DmcrSwitchService,
    private readonly _handleErrorService: HandleErrorService,
    private readonly _ngbModal: NgbModal
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.meter.currentValue != null)
      this.getDaylightSaving()
  }

  selectToUpdate(row: DaylightSavingDto) {
    this.toUpdate = row;
  }

  finishOperation() {
    this._ngbModal.dismissAll();
    this.toUpdate = null;
  }

  async sendCommand() {
    try {
      this.loadingCommands = true;
      await this._commandService.setDmrcSwitch(this.meter.serial, this.items[0]).toPromise(),
      this.loadingCommands = false;
    } catch (error) {
      this.loadingCommands = false;
      this._handleErrorService.handle(error)
    }

  }

  async getCommand() {
    try {
      this.loadingCommands = true;
      await this._commandService.getDmrcSwitch(this.meter.serial).toPromise()
      this.loadingCommands = false;
    } catch (error) {
      this.loadingCommands = false;
      this._handleErrorService.handle(error)
    }

  }

  openModal(content, isEdit = false) {
    this.isEdit = isEdit;
    this._ngbModal.open(content, { centered: true });
  }

  getDaylightSaving() {
    this._dmcrSwitchService.getBySerial(this.meter.serial)
      .toPromise()
      .then(res => {
        this.items = res.listOfItems;
        if(!this.items.length)
          this.items.push({holidayCapacitive: false, holidayInductive: false, saturdayCapacitive: false, 
          saturdayInductive: false, sundayCapacitive: false, sundayInductive: false, workdayCapacitive: false, workdayInductive: false})
      }).catch(error => {
        this._handleErrorService.handle(error);
        console.log(error)
      })
  }

  navigate(serial) {
    this.navigateToMeter.emit(serial);
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }
}
