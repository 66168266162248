import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DaylightSavingDto } from "src/app/core/models/DaylightSaving";
import { IPaginationResponseDTO } from "src/app/core/models/PaginationResponseDTO";
import { IogHttpService } from "src/app/security/iog-http.service";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class DaylightSavingService {
  static url: string = environment.apiUrlBack + '/daylight-saving';
  static commandUrl: string = environment.apiUrlBack + '/commands/';

  constructor(private httpService: IogHttpService) {}

  getAll(page: number = 1, pageSize: number = 15): Observable<DaylightSavingDto[]> {
    return this.httpService.get<DaylightSavingDto[]>(DaylightSavingService.url + '/get-all-no-page')
  }

  getBySerial(serial: string): Observable<DaylightSavingDto> {
    return this.httpService.get<DaylightSavingDto>(DaylightSavingService.url + '/serial/' + serial)
  }

  create(daylightSaving: DaylightSavingDto) {
    return this.httpService.post<DaylightSavingDto>(DaylightSavingService.url, daylightSaving);
  }

  delete(id?: string) {
    return this.httpService.delete<DaylightSavingDto>(DaylightSavingService.url + `/${id}`);
  }
}