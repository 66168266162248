import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConsumerDTO } from "src/app/core/models/ConsumerUnitDTO";
import { IogHttpService } from "src/app/security/iog-http.service";
import { environment } from "src/environments/environment";
@Injectable({
    providedIn: 'root'
})
export class ConsumerUnitService {
  static url: string = environment.apiUrlBack + '/consumer-units/';
  static commandUrl: string = environment.apiUrlBack + '/commands/';

  constructor(private httpService: IogHttpService) {}

  getByMeter(serial: string): Observable<ConsumerDTO> {
    return this.httpService.get<ConsumerDTO>(ConsumerUnitService.url + 'by-serial/'+ serial)
  }
}