import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IActiveCalendar, IDailyTariff, IDailyTariffTemplate } from "src/app/core/models/ActiveCalendarDTO";
import { IPaginationResponseDTO } from "src/app/core/models/PaginationResponseDTO";
import { IogHttpService } from "src/app/security/iog-http.service";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ActiveCalendarService {
  static url: string = environment.apiUrlBack + '/activecalendar';
  static commandUrl: string = environment.apiUrlBack + '/commands/';
  static dailyTariff: string = environment.apiUrlBack + '/daily-tariffs'
  static dailyDmcrTariff: string = environment.apiUrlBack + '/daily-dmcr'
  static meterDmcrProfile: string = environment.apiUrlBack + '/meter-dmcr-profiles'

  constructor(private httpService: IogHttpService) {}

  getBySerial(serial: string): Observable<IActiveCalendar[]> {
    const params = {
        serial
    }
    return this.httpService.get<IActiveCalendar[]>(ActiveCalendarService.url + `/by-serial`, {params})
  }

  createDailyTariffTemplate(template: IDailyTariffTemplate): Observable<IActiveCalendar> {
    return this.httpService.post<IActiveCalendar>(ActiveCalendarService.dailyTariff + '/create-template', template)
  }

  getDailyTariffBySerial(serial: string) {
    return this.httpService.get<IPaginationResponseDTO>(ActiveCalendarService.dailyTariff + `/with-pagination?serial=${serial}&page=1&pageSize=9999999`)
  }

  getDailyTariffTemplates() {
    return this.httpService.get<IPaginationResponseDTO>(ActiveCalendarService.dailyTariff + `/with-pagination?isTemplate=true&page=1&pageSize=9999999`)
  }

  getDailyTariffByMeterId(id: string) {
    const query = {
      meterProfileId: id
    }
    return this.httpService.get<IDailyTariff[]>(ActiveCalendarService.dailyTariff + `/by-meterprofileid`, {params: query})
  }

  getDailyDmcrTariffBySerial(serial: string) {
    return this.httpService.get<IPaginationResponseDTO>(ActiveCalendarService.dailyDmcrTariff + `/with-pagination?serialMeter=${serial}&page=1&pageSize=9999999`)
  }

  getMeterDmcrProfileBySerial(serial: string) {
    return this.httpService.get<IPaginationResponseDTO>(ActiveCalendarService.meterDmcrProfile + `/with-pagination?serial=${serial}&page=1&pageSize=9999999`)
  }

  getDailyDmcrTariffByMeterId(id: string) {
    const query = {
      meterProfileId: id
    }
    return this.httpService.get<IDailyTariff[]>(ActiveCalendarService.dailyDmcrTariff + `/by-meterprofileid`, {params: query})
  }
}