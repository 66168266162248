import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DaylightSavingDto } from "src/app/core/models/DaylightSaving";
import { IPaginationResponseDTO, PaginationResponseDTO } from "src/app/core/models/PaginationResponseDTO";
import { IogHttpService } from "src/app/security/iog-http.service";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class SpecialDaysService {
  static url: string = environment.apiUrlBack + '/meters/special-days-pagination';
  static commandUrl: string = environment.apiUrlBack + '/commands/';

  constructor(private httpService: IogHttpService) {}

  getAll(serial: string): Observable<PaginationResponseDTO> {
    const params = {
        page: 1,
        pageSize: 999,
        serial
    }
    return this.httpService.get<PaginationResponseDTO>(SpecialDaysService.url, {params})
  }

  getBySerial(serial: string): Observable<DaylightSavingDto> {
    return this.httpService.get<DaylightSavingDto>(SpecialDaysService.url + '/serial/' + serial)
  }

  create(daylightSaving: DaylightSavingDto) {
    return this.httpService.post<DaylightSavingDto>(SpecialDaysService.url, daylightSaving);
  }
}