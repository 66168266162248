import {Component, Input, OnInit} from '@angular/core';
import {MeterComissionedDTO} from '../../../core/models/MeterDTO';
import {IBillingPeriodDTO} from '../../../core/models/BillingPeriodDTO';
import {CommandsService} from '../../services/commands.service';
import {HandleErrorService} from '../../services/handle-error.service';

@Component({
  selector: 'app-billing-period-table',
  templateUrl: './billing-period-table.component.html',
  styleUrls: ['./billing-period-table.component.scss']
})
export class BillingPeriodTableComponent implements OnInit {

  @Input('data') items: IBillingPeriodDTO[] = [];
  @Input() isLoading = false;
  @Input() meter: MeterComissionedDTO;
  loadingCommands = false;

  constructor(private readonly _commandService: CommandsService, private readonly _handleErrorService: HandleErrorService ) { }

  ngOnInit() {
  }

  async sendCommand() {
    try {
      this.loadingCommands = true;
      await this._commandService.setAutomaticBillingPeriod(this.meter.serial, this.items[0].idk).toPromise();
      this.loadingCommands = false;
    } catch (error) {
      this.loadingCommands = false;
      this._handleErrorService.handle(error);
    }

  }

  async getCommand() {
    try {
      this.loadingCommands = true;
      await this._commandService.getAutomaticBillingPeriod(this.meter.serial).toPromise();
      this.loadingCommands = false;
    } catch (error) {
      this.loadingCommands = false;
      this._handleErrorService.handle(error);
    }

  }

}
