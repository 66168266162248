import { environment } from 'src/environments/environment';
import { Component } from "@angular/core";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  ano: number = new Date().getFullYear();


  getLogo(): string {
    if (environment.client)
      return './assets/images/logo2.png'

    return './assets/images/open-mdx.png'
  }
}
