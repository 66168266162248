import { IAuditoriaFilters } from './../../core/models/IAuditoriaDTO';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IogHttpService } from 'src/app/security/iog-http.service';
import { TicketsStatus } from 'src/app/core/models/TicketsDTO';
import { IPaginationResponseDTO } from 'src/app/core/models/PaginationResponseDTO';

export const DEFAULTICKESSTATUS: TicketsStatus[] = [TicketsStatus.Waiting, TicketsStatus.Executing, TicketsStatus.Finished, TicketsStatus.PartiallyFinished,
TicketsStatus.Interupted, TicketsStatus.MiddleWareOffline, TicketsStatus.MiddlewareNotResponding, TicketsStatus.FailedToContinue,
TicketsStatus.FailedToStart, TicketsStatus.Failed, TicketsStatus.CommandTimeOutReached, TicketsStatus.Cancelled
];
@Injectable({
    providedIn: 'root'
})
export class TicketsService {

    static url: string = environment.apiUrlBack + '/tickets';

    constructor(private http: IogHttpService) {

    }

    getTickets(serial: string, status: TicketsStatus[] = DEFAULTICKESSTATUS, pageSize: number = 15,
        sortOrder: string = 'updatedAt-desc', page: number = 1, searchString: string = '', startDateTime: string = '', endDateTime: string = ''
    ) {
        return this.http.post<IPaginationResponseDTO>(
            `${TicketsService.url}/${serial}?sortOrder=${sortOrder}&page=${page}&pageSize=${pageSize}&searchString=${searchString}&startDateTime=${startDateTime}&endDateTime=${endDateTime}`, { TicketStatusList: status }
        );
    }

    getSchedulesTickets(serial: string, status: TicketsStatus[] = [TicketsStatus.WaitingScheduleDateTime], pageSize: number = 15,
        sortOrder: string = 'ticketCreationDate-desc', page: number = 1, searchString: string = '', startDateTime: string = '', endDateTime: string = '') {
        return this.http.post<IPaginationResponseDTO>(
            `${TicketsService.url}/${serial}?sortOrder=${sortOrder}&page=${page}&pageSize=${pageSize}&searchString=${searchString}&startDateTime=${startDateTime}&endDateTime=${endDateTime}`, { TicketStatusList: status }
        )
    }

    cancelTicket(ticketID: string) {
        return this.http.get(`${TicketsService.url}/cancel/${ticketID}`).map(response => response.valueOf());
    }


    getAllTickets(filters: IAuditoriaFilters): Observable<IPaginationResponseDTO> {
        return this.http.get(TicketsService.url + `?page=${filters.page}&pageSize=${filters.pageSize}&sortBy=${filters.sortBy}&sortOrder=${filters.sortOrder}&endDate=${filters.endDate}&startDate=${filters.startDate}&email=${filters.userEmail || ''}&showSystem=${filters.showSystemTickets}&serialMeter=${filters.meterSerial}${_buildComandTypeQueryList(filters)}${_buildStatusQueryList(filters)}`);
    }





}

export function _buildStatusQueryList(filters: IAuditoriaFilters): string {
    if (filters.statusList.length >= 1) {
        let query = '&';
        filters.statusList.forEach(_status => {
            query += `statusList=${_status.value}&`;
        });
        return query.substring(0, query.length - 1);
    }
    return '';
}

export function _buildComandTypeQueryList(filters: IAuditoriaFilters): string {
    if (filters.commnadType.length >= 1) {
        let query = '&';
        filters.commnadType.forEach(_status => {
            query += `commandTypes=${_status.value}&`;
        });
        return query.substring(0, query.length - 1);
    }
    return '';
}
