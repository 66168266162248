import { Component, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, OnInit } from "@angular/core";
import * as XLS from "xlsx";
import {
  MeterComissionedDTO,
  MeterDTO,
  MeterPhase,
} from "src/app/core/models/MeterDTO";
import { PaginationResponseDTO } from "src/app/core/models/PaginationResponseDTO";
import { Utils } from "src/app/shared/utils";
import { HandleErrorService } from "../../services/handle-error.service";
import { CommandsService } from '../../services/commands.service';
import { DaylightSavingDto } from "src/app/core/models/DaylightSaving";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IDmcrSwitch } from "src/app/core/models/DmcrSwitchDTO";
import { IDropdownSettings } from "ng-multiselect-dropdown/multiselect.model";

export enum GeneralReading {
  CEMIG_ANNEX_3_BATTERY_STATE = 0,
  CEMIG_ANNEX_3_CLOSED_BILLINGS_COUNTER = 1,
 
  CEMIG_ANNEX_3_ENERGY_ACT_IMP_TOTAL = 2,       /* Totalizador Direto Geral */
  CEMIG_ANNEX_3_ENERGY_ACT_IMP_RATE_1 = 3,      /* Totalizador Direto Ponta */
  CEMIG_ANNEX_3_ENERGY_ACT_IMP_RATE_2 = 4,      /* Totalizador Direto Fora-Ponta */
  CEMIG_ANNEX_3_ENERGY_ACT_IMP_RATE_3 = 5,      /* Totalizador Direto Reservado */
  CEMIG_ANNEX_3_ENERGY_ACT_IMP_RATE_4 = 6,      /* Totalizador Direto Direto 4o Posto */
 
  CEMIG_ANNEX_3_ENERGY_ACT_EXP_TOTAL = 7,       /* Totalizador Reverso Geral */
  CEMIG_ANNEX_3_ENERGY_ACT_EXP_RATE_1 = 8,      /* Totalizador Reverso Ponta */
  CEMIG_ANNEX_3_ENERGY_ACT_EXP_RATE_2 = 9,      /* Totalizador Reverso Fora-Ponta */
  CEMIG_ANNEX_3_ENERGY_ACT_EXP_RATE_3 = 10,     /* Totalizador Reverso Reservado */
  CEMIG_ANNEX_3_ENERGY_ACT_EXP_RATE_4 = 11,     /* Totalizador Reverso Reservado */
 
  CEMIG_ANNEX_3_ENERGY_REA_IND_IMP_TOTAL = 12,  /* Reativo Indutivo Direto Geral */
  CEMIG_ANNEX_3_ENERGY_REA_IND_IMP_RATE_1 = 13, /* Reativo Indutivo Direto Ponta */
  CEMIG_ANNEX_3_ENERGY_REA_IND_IMP_RATE_2 = 14, /* Reativo Indutivo Direto Fora-Ponta */
  CEMIG_ANNEX_3_ENERGY_REA_IND_IMP_RATE_3 = 15, /* Reativo Indutivo Direto Reservado */
  CEMIG_ANNEX_3_ENERGY_REA_IND_IMP_RATE_4 = 16, /* Reativo Indutivo Direto 4o Posto */
 
  CEMIG_ANNEX_3_ENERGY_REA_CAP_IMP_TOTAL = 17,  /* Reativo Capacitivo Direto Geral */
  CEMIG_ANNEX_3_ENERGY_REA_CAP_IMP_RATE_1 = 18, /* Reativo Capacitivo Direto Ponta */
  CEMIG_ANNEX_3_ENERGY_REA_CAP_IMP_RATE_2 = 19, /* Reativo Capacitivo Direto Fora-Ponta */
  CEMIG_ANNEX_3_ENERGY_REA_CAP_IMP_RATE_3 = 20, /* Reativo Capacitivo Direto Reservado */
  CEMIG_ANNEX_3_ENERGY_REA_CAP_IMP_RATE_4 = 21, /* Reativo Capacitivo Direto 4o Posto */
 
  CEMIG_ANNEX_3_ENERGY_REA_IND_EXP_TOTAL = 22,  /* Reativo Indutivo Reverso Geral */
  CEMIG_ANNEX_3_ENERGY_REA_IND_EXP_RATE_1 = 23, /* Reativo Indutivo Reverso Ponta */
  CEMIG_ANNEX_3_ENERGY_REA_IND_EXP_RATE_2 = 24, /* Reativo Indutivo Reverso Fora-Ponta */
  CEMIG_ANNEX_3_ENERGY_REA_IND_EXP_RATE_3 = 25, /* Reativo Indutivo Reverso Reservado */
  CEMIG_ANNEX_3_ENERGY_REA_IND_EXP_RATE_4 = 26, /* Reativo Indutivo Reverso 4o Posto */
 
  CEMIG_ANNEX_3_ENERGY_REA_CAP_EXP_TOTAL = 27,  /* Reativo Capacitivo Reverso Geral */
  CEMIG_ANNEX_3_ENERGY_REA_CAP_EXP_RATE_1 = 28, /* Reativo Capacitivo Reverso Ponta */
  CEMIG_ANNEX_3_ENERGY_REA_CAP_EXP_RATE_2 = 29, /* Reativo Capacitivo Reverso Fora-Ponta */
  CEMIG_ANNEX_3_ENERGY_REA_CAP_EXP_RATE_3 = 30, /* Reativo Capacitivo Reverso Reservado */
  CEMIG_ANNEX_3_ENERGY_REA_CAP_EXP_RATE_4 = 31, /* Reativo Capacitivo Reverso 4o Posto */
 
  CEMIG_ANNEX_3_DEMAND_MAX_ACT_IMP_TOTAL = 32,  /* Demanda Máxima Direto Total */
  CEMIG_ANNEX_3_DEMAND_MAX_ACT_IMP_RATE_1 = 33, /* Demanda Máxima Direto Ponta */
  CEMIG_ANNEX_3_DEMAND_MAX_ACT_IMP_RATE_2 = 34, /* Demanda Máxima Direto Fora-Ponta */
  CEMIG_ANNEX_3_DEMAND_MAX_ACT_IMP_RATE_3 = 35, /* Demanda Máxima Direto Reservado */
  CEMIG_ANNEX_3_DEMAND_MAX_ACT_IMP_RATE_4 = 36, /* Demanda Máxima Direto Tipo D */
 
  CEMIG_ANNEX_3_DEMAND_ACC_ACT_IMP_TOTAL = 37,  /* Demanda Acumulado Direto Total */
  CEMIG_ANNEX_3_DEMAND_ACC_ACT_IMP_RATE_1 = 38, /* Demanda Acumulado Direto Ponta */
  CEMIG_ANNEX_3_DEMAND_ACC_ACT_IMP_RATE_2 = 39, /* Demanda Acumulado Direto Fora-Ponta */
  CEMIG_ANNEX_3_DEMAND_ACC_ACT_IMP_RATE_3 = 40, /* Demanda Acumulado Direto Reservado */
  CEMIG_ANNEX_3_DEMAND_ACC_ACT_IMP_RATE_4 = 41, /* Demanda Acumulado Direto Tipo D */
 
  CEMIG_ANNEX_3_DEMAND_MAX_ACT_EXP_TOTAL = 42,  /* Demanda Máxima Reverso Ponta Total */
  CEMIG_ANNEX_3_DEMAND_MAX_ACT_EXP_RATE_1 = 43, /* Demanda Máxima Reverso Ponta */
  CEMIG_ANNEX_3_DEMAND_MAX_ACT_EXP_RATE_2 = 44, /* Demanda Máxima Reverso Fora-Ponta */
  CEMIG_ANNEX_3_DEMAND_MAX_ACT_EXP_RATE_3 = 45, /* Demanda Máxima Reverso Reservado */
  CEMIG_ANNEX_3_DEMAND_MAX_ACT_EXP_RATE_4 = 46, /* Demanda Máxima Reverso Tipo D */
 
  CEMIG_ANNEX_3_DEMAND_ACC_ACT_EXP_TOTAL = 47,  /* Demanda Acumulado Reverso Total */
  CEMIG_ANNEX_3_DEMAND_ACC_ACT_EXP_RATE_1 = 48, /* Demanda Acumulado Reverso Ponta */
  CEMIG_ANNEX_3_DEMAND_ACC_ACT_EXP_RATE_2 = 49, /* Demanda Acumulado Reverso Fora-Ponta */
  CEMIG_ANNEX_3_DEMAND_ACC_ACT_EXP_RATE_3 = 50, /* Demanda Acumulado Reverso Reservado */
  CEMIG_ANNEX_3_DEMAND_ACC_ACT_EXP_RATE_4 = 51, /* Demanda Acumulado Reverso Tipo D */
 
  CEMIG_ANNEX_3_UFER_TOTAL = 52,      /* Total */
  CEMIG_ANNEX_3_UFER_RATE_1 = 53,     /* Ponta */
  CEMIG_ANNEX_3_UFER_RATE_2 = 54,     /* Fora-Ponta */
  CEMIG_ANNEX_3_UFER_RATE_3 = 55,     /* Reservado */
 
  CEMIG_ANNEX_3_DMCR_ACC_RATE_1 = 56, /* Ponta */
  CEMIG_ANNEX_3_DMCR_ACC_RATE_2 = 57, /* Fora-Ponta */
  CEMIG_ANNEX_3_DMCR_ACC_RATE_3 = 58, /* Reservado */
 
  CEMIG_ANNEX_3_DMCR_CUR_RATE_1 = 59, /* Ponta */
  CEMIG_ANNEX_3_DMCR_CUR_RATE_2 = 60, /* Fora-Ponta */
  CEMIG_ANNEX_3_DMCR_CUR_RATE_3 = 61, /* Reservado */
}

@Component({
  selector: "app-general-readings",
  templateUrl: "./general-readings.component.html",
  styleUrls: ["./general-readings.component.scss"],
})
export class GeneralReadingsComponent implements OnChanges {
  @Input("data") items: IDmcrSwitch[] = [];
  @Input() meter: MeterComissionedDTO;
  @Input() page: PaginationResponseDTO;
  @Input() showView = true;
  @Input() isLoading = false;
  @Input() isReloading = false;
  @Input() tickets = [];
  @Input() answer = []

  @Output() navigateToMeter = new EventEmitter();

  allDaylightSaving: DaylightSavingDto[] = [];

  public selecteds = [];

  public MeterPhase = MeterPhase;
  public MeterDTO = MeterDTO;
  public Utils = Utils;
  public MeterComissioned = MeterComissionedDTO;

  public startDate = "";
  public endDate = "";
  public searchString = "";
  public sortOrder = "updatedAt-desc";
  public currentPage = 0;
  public pageSize = 15;
  public placeHolderLabel =
    "Pesquisar pelo serial, código de instalação , EUI do modem ou Fabricante";
  public filledColor: string = '#fff';

  @ViewChild('ngxTable', { static: false }) table: any;
  isEdit = false;
  toUpdate: DaylightSavingDto = null;
  loadingCommands: boolean = false;

  generalReadings = Object.values(GeneralReading).filter(d => Number.isInteger(d));
  meterDropDownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'serial',
    textField: 'serial',
    allowSearchFilter: true,
    noDataAvailablePlaceholderText: '',
    searchPlaceholderText: 'Pesquise por serial',
    selectAllText: 'Selecionar todos',
    unSelectAllText: 'Remover todos',
    enableCheckAll: false,
};
  constructor(
    private readonly _commandService: CommandsService,
    private readonly _ngbModal: NgbModal,
    private readonly _handleError: HandleErrorService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes)
    if(changes.answer.currentValue)
      this.answer = JSON.parse(changes.answer.currentValue)
  }

  finishOperation() {
    this._ngbModal.dismissAll();
  }


  openModal(content) {
    this._ngbModal.open(content, { centered: true });
  }

  navigate(serial) {
    this.navigateToMeter.emit(serial);
  }

  async requestDemands() {
    try {
      if(!this.selecteds.length) return;
      this.isLoading = true;
      await this._commandService.getReadings(this.meter.serial, this.selecteds).toPromise();
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      this._handleError.handle(error);

    }
    console.log(this.selecteds)
  }

  select(demand: number) {
    if(this.selecteds.includes(demand))
      this.selecteds = this.selecteds.filter(x => x != demand)
    else
      this.selecteds.push(demand)
    console.log(demand, this.selecteds)
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }
}
