import { Component, Input, OnInit } from '@angular/core';
import { ConsumerUnitService } from '../../services/consumer-unit.service';
import { ConsumerDTO } from 'src/app/core/models/ConsumerUnitDTO';
import { MeterDTO } from 'src/app/core/models/MeterDTO';
import { HandleErrorService } from "src/app/shared/services/handle-error.service";

@Component({
  selector: 'app-consumer-info',
  templateUrl: './consumer-info.component.html',
  styleUrls: ['./consumer-info.component.scss']
})
export class ConsumerInfoComponent {

  consumerLoading = false;
  ConsumerDTO = ConsumerDTO;

  @Input() consumer: ConsumerDTO;
  @Input() meter: MeterDTO;
  constructor(
    private consumerUnitServices: ConsumerUnitService,
    private handleService: HandleErrorService,
  ) { }


   getConsumer() {
    this.consumerLoading = true;
    this.consumerUnitServices
      .getByMeter(this.meter.serial)
      .toPromise()
      .then((res) => {
            this.consumer = res;
      })
      .catch(err => {
        this.handleService.handle(err);
        this.consumerLoading = false;
      });
  }
}
