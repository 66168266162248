import { Component, Input } from "@angular/core";

export interface INavHorizontal {
    name: string;
    query: string;
}

@Component({
    styleUrls: ['./nav-horizontal.component.scss'],
    templateUrl: './nav-horizontal.component.html',
    selector: 'app-nav-horizontal'
})
export class NavHorizontalComponent {
    @Input() nav: INavHorizontal[] = []
}