export enum CommandStatus {
    WAITING_COMMAND = 'WAITING_COMMAND',
    REGISTERING_KEYS = 'REGISTERING_KEYS',
    REGISTERING_DATETIME = 'REGISTERING_DATETIME',
    REQUESTING_DATE = 'REQUESTING_DATE',
    SMI_INFO_REQUEST = 'SMI_INFO_REQUEST',
    REQUESTING_RELAY_STATUS = 'REQUESTING_RELAY_STATUS',
    SET_DISPLAY_CONFIGURATION_MANUAL = 'SET_DISPLAY_CONFIGURATION_MANUAL',
    METER_SET_QUALITY_MEASURE_PARAMS = 'METER_SET_QUALITY_MEASURE_PARAMS',
    GET_QEE_BUFFER = 'GET_QEE_BUFFER',
    GET_ENERGY_TOTALIZER = 'GET_ENERGY_TOTALIZER',
    METER_RECONVER_EVENT_QEE_INDICATOR = 'METER_RECONVER_EVENT_QEE_INDICATOR',
    CHANGING_RELAY_STATUS = 'CHANGING_RELAY_STATUS',
    REQUESTING_SERIAL = 'REQUESTING_SERIAL',
    CHANGING_COLLECTIVE_CUT_ALARM = 'CHANGING_COLLECTIVE_CUT_ALARM',
    ROLLBACK = 'ROLLBACK',
    INSTANT_MEASURE = 'INSTANT_MEASURE',
    NIC_RESET = 'NIC_RESET',
    SET_CURRENT = 'SET_CURRENT',
    GET_QEE_INDICATORS = 'GET_QEE_INDICATORS',
    UPLINK_PERIOD = 'UPLINK_PERIOD',
    RECOVER_MASS_MEMORY = 'RECOVER_MASS_MEMORY',
    RECOVER_FROZEN_DATA = 'RECOVER_FROZEN_DATA',
    RECOVER_EVENT_QEE_INDICATOR = 'RECOVER_EVENT_QEE_INDICATOR',
    GBT = 'GBT',
    REQUESTING_RELAY_STATUS_ALL_SMC = 'REQUESTING_RELAY_STATUS_ALL_SMC',
    REQUEST_DOOR_STATUS = 'REQUEST_DOOR_STATUS',
    RELAY_ON_ALL_METERS_SMC = 'RELAY_ON_ALL_METERS_SMC',
    METER_ADDED_TO_SMC = 'METER_ADDED_TO_SMC',
    METER_REMOVED_FROM_SMC = 'METER_REMOVED_FROM_SMC',
    GET_VOLTAGE_OUT_SHORT = 'GET_VOLTAGE_OUT_SHORT',
    GET_VOLTAGE_OUT_LONG = 'GET_VOLTAGE_OUT_LONG',
    MARK_ALARM_AS_READ = 'MARK_ALARM_AS_READ',
    SET_INTERVAL_OF_QEE = 'SET_INTERVAL_OF_QEE',
    SET_INTERVAL_OF_LONG_OUT_VOLTAGE = 'SET_INTERVAL_OF_LONG_OUT_VOLTAGE',
    SET_STANDARD_FROZEN_DATA = 'SET_STANDARD_FROZEN_DATA',
    SET_SMI_VOLTAGE = 'SET_SMI_VOLTAGE',
    GET_VOLTAGE = 'GET_VOLTAGE',
    SET_DISPLAY_CONFIGURATION = 'SET_DISPLAY_CONFIGURATION',
    CHANGING_NETWORK_TYPE = "CHANGING_NETWORK_TYPE",
    GETTING_NETWORK_TYPE = "GETTING_NETWORK_TYPE",
    GETTING_NETWORK_INFO = "GETTING_NETWORK_INFO",
    CHANGING_NETWORK_TECH = "CHANGING_NETWORK_TECH",
    GETTING_NETWORK_OPERATOR = "GETTING_NETWORK_OPERATOR",
    CHANGING_APN = "CHANGING_APN"
}

interface ICommandDTO {
    command: CommandStatus;

}
export class CommandDTO implements ICommandDTO {
    public command: CommandStatus;

    public static getCommandStatus(command: CommandStatus): string {
        switch (command) {
            case CommandStatus.ROLLBACK:
                return 'Executando RollBack';
            case CommandStatus.WAITING_COMMAND:
                return 'Aguardando Comando';
            case CommandStatus.REGISTERING_KEYS:
                return 'Registrando Chaves';
            case CommandStatus.REQUESTING_DATE:
                return 'Requisitando Data';
            case CommandStatus.CHANGING_RELAY_STATUS:
                return 'Alterando Estado do Relé';
            case CommandStatus.REQUESTING_RELAY_STATUS:
                return 'Requisitando Estado do Relé';
            case CommandStatus.REGISTERING_DATETIME:
                return 'Registrando Data e Hora';
            case CommandStatus.REQUESTING_SERIAL:
                return 'Requisitando Serial';
            case CommandStatus.CHANGING_COLLECTIVE_CUT_ALARM:
                return 'Alterando o status de corte coletivo';
            case CommandStatus.INSTANT_MEASURE:
                return 'Solicitando medida Instantânea';
            case CommandStatus.NIC_RESET:
                return 'Reset da NIC';
            case CommandStatus.SET_CURRENT:
                return 'Modificando corrente de corte';
            case CommandStatus.GET_QEE_INDICATORS:
                return 'Solicitando parametros de qualidade';
            case CommandStatus.UPLINK_PERIOD:
                return 'Alterando Periodo de Uplink de leitura';
            case CommandStatus.RECOVER_FROZEN_DATA:
                return 'Recuperando Energia';
            case CommandStatus.RECOVER_MASS_MEMORY:
                return 'Recuperando Memória de Massa';
            case CommandStatus.RECOVER_EVENT_QEE_INDICATOR:
                return 'Recuperando parametros de qualidade';
            case CommandStatus.GBT:
                return 'GBT';
            case CommandStatus.REQUESTING_RELAY_STATUS_ALL_SMC:
                return 'Solicitando Status  dos medidores';
            case CommandStatus.REQUEST_DOOR_STATUS:
                return 'Obtendo Status do corte Coletivo';
            case CommandStatus.RELAY_ON_ALL_METERS_SMC:
                return 'Religando todos os clientes';
            case CommandStatus.METER_ADDED_TO_SMC:
                return 'Adicionando medidores ao smc (Sistema)';
            case CommandStatus.METER_REMOVED_FROM_SMC:
                return 'Removendo medidores do smc (Sistema)';
            case CommandStatus.GET_VOLTAGE_OUT_SHORT:
                return 'Obtendo Alarme Voltage Out Short';
            case CommandStatus.GET_VOLTAGE_OUT_LONG:
                return 'Obtendo Alarme Voltage Out Longo';
        }
    }

    public static getCommandStatusClass(command: CommandStatus): string {
        switch (command) {
            case CommandStatus.WAITING_COMMAND:
                return 'badge badge-success mr-1';
            case CommandStatus.NIC_RESET:
                return 'badge badge-warning mr-1';
            default:
                return 'badge badge-info mr-1';
        }
    }
}
