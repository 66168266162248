import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { EnumMapperDTO } from "src/app/core/models/EnumMapperDTO";
import { CommandTickets, CommandType, Status, StatusCommand, TicketDTO, TicketsStatus } from "src/app/core/models/TicketsDTO";
import { TranslateInCodeService } from "../../services/translate-in-code.service";
import { TranslateService } from "@ngx-translate/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Utils } from "../../utils";

@Component({
  selector: "app-card-command",
  templateUrl: "./card-command.component.html",
  styleUrls: ["./card-command.component.scss"],
})
export class CardCommandComponent implements OnInit {
  @Input() ticket: TicketDTO;
  ticketDTO = TicketDTO;
  EnumMapperDTO: EnumMapperDTO[];
  TicketStatus = TicketsStatus;
  Utils = Utils;
  CommandType = CommandType;

  constructor(private _translateInCode: TranslateInCodeService,
    private _translateService: TranslateService, private _modal: NgbModal) {}

    async ngOnInit() {
        await this.getTranslate();
        this._translateService.onLangChange.subscribe(async () => {
            await this.getTranslate();
        });
    }

    async getTranslate() {
        this
          ._translateInCode
          .getTranslateEnunsInArray('Tickets.Enuns', [TicketsStatus, StatusCommand, CommandType])
          .then((res) => {
            this.EnumMapperDTO = res;
          });
      }

      verify() {
        if(this.ticket.status == Status.Executing || this.ticket.status == Status.Waiting) return false;
        return true;
      }

      openModal(ctx) {
        if(!this.verify()) return;
        this._modal.open(ctx, { centered: true })
      }

      closeModal() {
        this._modal.dismissAll()
      }

}
