import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IogHttpService } from 'src/app/security/iog-http.service';
import { AbsolutePeriod, VoltageValues } from 'src/app/core/models/SmcDTO';
import { IProcessCommand, ProcessType } from 'src/app/core/models/ClusterRelayActionDTO';
import { SpecialDayDTO } from 'src/app/core/models/SpecialDayDTO';
import { IDmcrSwitch } from 'src/app/core/models/DmcrSwitchDTO';
import { IDailyTariff, IWeekTariff } from 'src/app/core/models/ActiveCalendarDTO';
import { IDailyDMCRDTO } from 'src/app/core/models/MeterDmcrProfileDTO';

interface IMassCommand {
  processType: ProcessType;
  serialCommands: IProcessCommand[];
}

@Injectable({ providedIn: 'root' })
export class CommandsService {
  static url = environment.apiUrlBack + '/commands/';
  constructor(private http: IogHttpService) { }

  massCommand(commands: IMassCommand) {
    return this.http.post(`${CommandsService.url}mass-command`, commands);
  }

  relayOn(serial) {
    return this.http.put(`${CommandsService.url}relay-on/${serial}`, '');
  }

  relayOff(serial) {
    return this.http.put(`${CommandsService.url}relay-off/${serial}`, '');
  }

  getRelayStatus(serial) {
    return this.http.get(`${CommandsService.url}get-relay/${serial}`);
  }

  getSmcMetersList(smcSerial: string) {
    return this.http.get(CommandsService.url + `get-serial-meters-smc/${smcSerial}`);
  }

  disableCollectiveCut(smcSerial: string, hours: number) {
    return this.http.put(
      `${CommandsService.url}disable-collective-cut/${smcSerial}/${hours}`,
      ''
    );
  }

  enabaleColletiveCut(smcSerial: string) {
    return this.http.put(
      `${CommandsService.url}enable-collective-cut/${smcSerial}`,
      ''
    );
  }

  getColletiveCutStatus(smcSerial: string) {
    return this.http.get(CommandsService.url + `get-door-status/${smcSerial}`, '');
  }

  getAbsoluteDoorStatus(smcSerial: string) {
    return this.http.get(CommandsService.url + `get-door-status/absolute/${smcSerial}`, '');
  }

  rollBack(smcSerial: string) {
    return this.http.put(
      `${CommandsService.url}relay-rollback/${smcSerial}`,
      ''
    );
  }

  getInstantMeasure(meterSerial: string) {
    return this.http.get(
      `${CommandsService.url}instant-measure/${meterSerial}`
    );
  }

  resetNic(serial: string, seconds: number = 10) {
    return this.http.put(
      `${CommandsService.url}nic-reset/${serial}?seconds=${seconds}`,
      ''
    );
  }

  getBilling(serial: string) {
    return this.http
      .get(environment.apiUrlBack + `/billing/${serial}`)
      .map((response) => response.valueOf());
  }

  updateDateTime(serial: string) {
    return this.http.put(`${CommandsService.url}update-datetime/${serial}`, '');
  }

  changeSmcTimeOut(serial: string, timeInMinutes: number, startDateTime) {
    return this.http.post(
      CommandsService.url +
      `set-smc-uplink-interval/${serial}?startTime=${startDateTime}&periodInMinutes=${timeInMinutes}`,
      {}
    );
  }

  changeSmiTimeOut(serial: string, timeInMinutes: number, startDateTime) {
    return this.http.post(
      CommandsService.url +
      `set-smi-uplink-interval/${serial}?startTime=${startDateTime}&periodInMinutes=${timeInMinutes}`,
      {}
    );
  }

  getAllRelayStatus(smcSerial: string) {
    return this.http.get(
      `${CommandsService.url}get-relay-all-meters-smc/${smcSerial}`
    );
  }

  relayOnAllMetersSmc(serialSmc: string) {
    return this.http.put(`${CommandsService.url}relay-on-all-meters-smc/${serialSmc}`, {});
  }

  recoverEnergy(smcSerial: string, quantity: number) {
    return this.http.post(`${CommandsService.url}recover-energy-smc/${smcSerial}?quantity=${quantity}`, {});
  }

  getInstantMeasureSmc(serial: string) {
    return this.http.get(`${CommandsService.url}instant-measure-smc/${serial}`);
  }

  recoverVoltageOutShort(meterSerial: string) {
    return this.http.get(`${CommandsService.url}voltage-out-short/${meterSerial}`);
  }


  recoverVoltageOutLong(meterSerial: string) {
    return this.http.get(`${CommandsService.url}voltage-out-long/${meterSerial}`);
  }

  setStandartFrozenData({
    serial
  }) {
      return this.http.post(CommandsService.url + `set-standard-frozen-data/${serial}`, {});
  }


  setSmiQueeInterval({
    serial,
    startTime,
    periodInMinutes
  }) {
      return this.http.post(CommandsService.url + `set-smi-qee-interval/${serial}?startTime${startTime}&periodInMinutes=${periodInMinutes}`, {});
  }

  setSmiLongOutVoltageInterval({
    serial,
    startTime,
    periodInMinutes
  }) {
      return this.http.post(CommandsService.url + `set-smi-long-out-voltage-interval/${serial}?startTime=${startTime}&periodInMinutes=${periodInMinutes}`, {});
  }

  requestVoltageOutShortByQuantity(serial: string, value: number) {
    return this.http.post(`${CommandsService.url}voltage-out-short-by-obj/${serial}?entries=` + value, {});
  }

  requestVoltageOutLongByQuantity(serial: string, value: number) {
    return this.http.post(`${CommandsService.url}voltage-out-long-by-obj/${serial}?entries=` + value, {});
  }


  changeNetwork(serial: string, value: number) {
    return this.http.put(`${CommandsService.url}change-network-type/${serial}?type=` + value, {});
  }

  changeTech(serial: string, value: number) {
    return this.http.put(`${CommandsService.url}change-network-tech/${serial}?type=` + value, {});
  }

  getTech(serial: string) {
    return this.http.get(`${CommandsService.url}network-tech/${serial}`);
  }

  getNetwork(serial: string) {
    return this.http.get(`${CommandsService.url}network-type/${serial}`);
  }

  changeAbsolutePeriod(period: AbsolutePeriod, serial: string) {
    return this.http.post(`${CommandsService.url}set-smc-uplink-interval/${serial}/absolute?period=` + period, {});
  }

  setVoltage(value: VoltageValues, serial: string) {
    return this.http.put(`${CommandsService.url}set-voltage/${serial}?value=` + value, {});
  }

  getVoltage(serial: string) {
    return this.http.get(`${CommandsService.url}get-voltage/${serial}`);
  }

  setDisplayConfiguration(serial: string) {
    return this.http.put(`${CommandsService.url}set-display-configuration/${serial}`, {});
  }

  setDisplayConfigurationManual(serial: string) {
    return this.http.put(`${CommandsService.url}set-display-configuration-manual/${serial}`, {});
  }

  recoverActiveEnergy(serial: string) {
    return this.http.put(`${CommandsService.url}get-energy-totalizer/${serial}`, {});
  }

  recoverEventQeeIndicator(serial: string) {
    return this.http.get(`${CommandsService.url}recover-event-qee-indicator/${serial}`, {});
  }

  getCellIdAndIp(serial: string) {
    return this.http.post(`${CommandsService.url}get-cell-id-and-ip/${serial}`, {});
  }

  setEventQeeIndicator(serial: string) {
    return this.http.get(`${CommandsService.url}set-quality-measure-params/${serial}`, {});
  }

  setDaylightSavingDeviation(serial: string, deviation: number) {
    const params = {
      deviation
    };
    return this.http.put(`${CommandsService.url}set-daylight-deviation/${serial}`, {}, { params });
  }

  setDaylightSavingBegin(serial: string, dateTime: string) {
    const params = {
      dateTime
    };
    return this.http.put(`${CommandsService.url}set-daylight-begin/${serial}`, {}, { params });
  }

  setDaylightSavingEnabled(serial: string, enabled: boolean) {
    const params = {
      enabled
    };
    return this.http.put(`${CommandsService.url}set-daylight-enabled/${serial}`, {}, { params });
  }

  setDaylightSavingEnd(serial: string, dateTime: string) {
    const params = {
      dateTime
    };
    return this.http.put(`${CommandsService.url}set-daylight-end/${serial}`, {}, { params });
  }

  getDaylightSavingDeviation(serial: string) {
    return this.http.get(`${CommandsService.url}get-daylight-deviation/${serial}`);
  }

  getDaylightSavingBegin(serial: string) {
    return this.http.get(`${CommandsService.url}get-daylight-begin/${serial}`);
  }

  getDaylightSavingEnabled(serial: string) {
    return this.http.get(`${CommandsService.url}get-daylight-enabled/${serial}`);
  }

  getDaylightSavingEnd(serial: string) {
    return this.http.get(`${CommandsService.url}get-daylight-end/${serial}`);
  }

  getSpecialDaysTable(serial: string) {
    return this.http.get(`${CommandsService.url}get-special-days-table/${serial}`);
  }

  setSpecialDaysTable(serial: string, specialDays: SpecialDayDTO[]) {
    return this.http.post(`${CommandsService.url}set-special-days-table/${serial}`, specialDays);
  }

  deleteSpecialDaysTable(serial: string, specialDays: SpecialDayDTO[]) {
    return this.http.post(`${CommandsService.url}delete-special-days-table/${serial}`, specialDays);
  }

  getDayProfileActiveActivityCalendar(serial: string) {
    return this.http.post(`${CommandsService.url}get-day-profile-active-activity-calendar/${serial}`, {});
  }

  getDayProfilePassiveActivityCalendar(serial: string) {
    return this.http.post(`${CommandsService.url}get-day-profile-passive-activity-calendar/${serial}`, {});
  }

  getWeekProfileActiveActivityCalendar(serial: string) {
    return this.http.post(`${CommandsService.url}get-week-profile-active-activity-calendar/${serial}`, {});
  }

  getWeekProfilePassiveActivityCalendar(serial: string) {
    return this.http.post(`${CommandsService.url}get-week-profile-passive-activity-calendar/${serial}`, {});
  }

  getActivateTimeProfilePassiveActivityCalendar(serial: string) {
    return this.http.get(`${CommandsService.url}get-activate-time-profile-passive-activity-calendar/${serial}`);
  }

  setDayPassiveActivityCalendar(serial: string, name: string, dailyTariffs: IDailyTariff[]) {
    return this.http.post(`${CommandsService.url}set-day-passive-activity-calendar/${serial}`, {serial, name, dailyTariffs})
  }

  setWeekPassiveActivityCalendar(serial: string, week: IWeekTariff) {
    week.serial = serial;
    return this.http.post(`${CommandsService.url}set-week-passive-active-calendar/${serial}`, week)
  }

  setActivateTimeProfile(serial: string, date: Date) {
    return this.http.post(`${CommandsService.url}set-activate-time-profile-passive-activity-calendar/${serial}?serial=${serial}&dateTime=${date}`, date)
  }

  setActivateStausProfile(serial: string, value: number) {
    return this.http.post(`${CommandsService.url}set-activate-status-profile-activity-calendar/${serial}?serial=${serial}&value=${value}`, value)
  }

  getDmrcSwitch(serial: string) {
    return this.http.get(`${CommandsService.url}get-DMCR-switch/${serial}`);
  }

  setDmrcSwitch(serial: string, switchDmcr: IDmcrSwitch) {
    switchDmcr.serial = serial;
    return this.http.post(`${CommandsService.url}set-DMCR-switch/${serial}`, switchDmcr);
  }

  getAutomaticBillingPeriod(serial: string) {
    return this.http.get(`${CommandsService.url}get-automatic-billing-period/${serial}`);
  }

  setAutomaticBillingPeriod(serial: string, billingPeriod: string) {
    return this.http.get(`${CommandsService.url}get-automatic-billing-period/${serial}?billingPeriod=${billingPeriod}`);
  }


  // DMCRS
  getDmcrDayProfileActive(serial: string) {
    return this.http.get(`${CommandsService.url}get-DMRC-day-profile-active/${serial}`);
  }

  getDMCRDayProfilePassive(serial: string) {
    return this.http.get(`${CommandsService.url}get-DMRC-day-profile-passive/${serial}`);
  }

  getDmcrWeekProfileActive(serial: string) {
    return this.http.get(`${CommandsService.url}get-DMRC-week-profile-active/${serial}`);
  }

  getDmcrWeekProfilePassive(serial: string) {
    return this.http.get(`${CommandsService.url}get-DMRC-week-profile-passive/${serial}`);
  }

  getDmcrActivateTimeProfilePassive(serial: string) {
    return this.http.get(`${CommandsService.url}get-DMRC-activate-time-profile-passive/${serial}`);
  }

  setDMRCDayPassiveActivityCalendar(serial: string, name: string, drmcDailyTariffs: IDailyDMCRDTO[]) {
    return this.http.post(`${CommandsService.url}set-DMRC-day-passive-activity-calendar/${serial}`, {serial, name, drmcDailyTariffs})
  }

  setDmrcWeekPassiveActivityCalendar(serial: string, week: IWeekTariff) {
    week.serial = serial;
    return this.http.post(`${CommandsService.url}set-DMRC-week-passive-activity-calendar/${serial}`, week)
  }

  setDmrcActivateTimeProfile(serial: string, date: Date) {
    return this.http.post(`${CommandsService.url}set-DMRC-activate-time-profile-passive/${serial}?serial=${serial}&dateTime=${date}`, date)
  }

  setDmrcActivateStatusProfile(serial: string, value: number) {
    return this.http.post(`${CommandsService.url}set-DMRC-activate-status-profile-passive/${serial}?serial=${serial}&value=${value}`, value)
  }

  getTaxPage(serial: string) {
    return this.http.get(`${CommandsService.url}get-tax-page/${serial}`)
  }

  getDemands(serial: string, demands: number[]) {
    return this.http.post(`${CommandsService.url}read-demand-register/${serial}`, demands);
  }

  getReadings(serial: string, demands: number[]) {
    return this.http.post(`${CommandsService.url}get-general-reading/${serial}`, demands);
  }

}
