import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FiscalPageDTO } from "src/app/core/models/FiscalPageDTO";
import { PaginationResponseDTO } from "src/app/core/models/PaginationResponseDTO";
import { Utils } from "../../utils";

@Component({
    selector: 'app-fiscal-table',
    templateUrl: './fiscal-table.component.html',
    styleUrls: ["./fiscal-table.component.scss"],

  })
  export class FiscalComponent {
    @Input() rows: FiscalPageDTO[] = [];
    columns: any[] = [];
    @Input() page = new PaginationResponseDTO();
    @Input() isLoading = false;

    @Output() changePage = new EventEmitter();
    @Output() refreshPage = new EventEmitter();
    @Output() recoverData = new EventEmitter();

    Utils = Utils;

    hasNonNullValue(key: string): boolean {
        return this.rows.some(row => row[key] !== null && row[key] !== undefined);
    }

    refresh() {
        this.refreshPage.emit()
    }
    
    filterByPage(page) {
        console.log(page)
       this.changePage.emit({ page: page.page == 1 ? 0 : page.page, pageSize: this.page.pageSize });
    }

    changeSizeValue(value) {
        this.changePage.emit({ page: 0, pageSize: value });
    }

    recover() {
        this.recoverData.emit();
    }
}