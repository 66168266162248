import { Component, Input, EventEmitter, Output, ViewChild, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as XLS from "xlsx";

import { IRelayActionProcess } from './../../../core/models/ClusterRelayActionDTO';
import { PaginationResponseDTO } from 'src/app/core/models/PaginationResponseDTO';
import { TicketsStatus } from 'src/app/core/models/ReceiptDTO';
import { CommandType, ITicketsDTO, Status, StatusCommand, TicketDTO } from 'src/app/core/models/TicketsDTO';
import { HubService } from '../../services/hub.service';
import { Utils } from '../../utils';
import { echartStyles } from '../echart/echart.component';
import { CommandHubDTO, CommandHubType } from 'src/app/core/models/command-event/CommandHubDTO';
import { FileService } from '../../services/file.service';
import { TranslateInCodeService } from '../../services/translate-in-code.service';
import { EnumMapperDTO } from 'src/app/core/models/EnumMapperDTO';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown/multiselect.model';

@Component({
  selector: 'app-process-info',
  templateUrl: './process-info.component.html',
  styleUrls: ['./process-info.component.scss'],
})
export class ProcessInfoComponent implements OnChanges, OnInit {
  @ViewChild('ngxTable', { static: false }) table: any;
  @Input() process: IRelayActionProcess = {};
  @Input() isLoading = true;
  @Input() showChart: boolean = true;
  @Input() deviceDevEui: string;
  @Input() page: PaginationResponseDTO = new PaginationResponseDTO();
  @Input() isReloading: boolean = false;

  @Output() reload: EventEmitter<string> = new EventEmitter();
  @Output() navigate = new EventEmitter();
  @Output() resetProcess = new EventEmitter();

  CommandStatus = Status;
  CommandType = CommandType;
  TicketDTO = TicketDTO;
  TicketStatus = TicketsStatus;
  CommandHUbDTO = CommandHubDTO;
  public TICKECTS_STATUS = [
    {
      value: Status.Waiting,
      label: 'Aguardando'
    },
    {
      value: Status.Executing,
      label: 'Executando'
    }
    ,
    {
      value: Status.Finished,
      label: 'Finalizado'
    },
    {
      value: Status.Cancelled,
      label: 'Cancelado'
    },
    {
      value: Status.Failed,
      label: 'Falhou'
    }
  ];
  selectedTicketStatus: Array<string> = new Array<string>();


  public progressChartOptions;
  public tickets: Array<ITicketsDTO> = [];

  Utils = Utils;

  public connection: signalR.HubConnection;
  pageEvent: PageEvent;

  private expandedId = [];

  iWantToCloseWs: boolean = false;

  EnumMappersDTO: EnumMapperDTO[];
  constructor(
    private _hubServices: HubService,
    private _modalService: NgbModal,
    public fileService: FileService,
    private translateInCode: TranslateInCodeService,
    private translate: TranslateService
  ) {

  }

  async ngOnInit() {
    await this.getTranslate();
    this.translate.onLangChange.subscribe(e => this.getTranslate());

    this.openConnection();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.process !== undefined && this.process !== null) {
      this.tickets = this.process.tickets;
      this.progressChartOptions = {
        ...echartStyles, ...{
          tooltip: {
            triggerOn: 'mousemove',
            trigger: 'item'
          },
          legend: {
            orient: 'vertical',
            left: 'left',
            data: ['Corte', 'Obter Estado', 'Religamento']
          },
          series: [
            {
              name: 'Tipos de Comandos',
              type: "pie",
              data: [
                {
                  name: 'Corte',
                  value: this.process.ticketsRelayOff,

                  itemStyle: {
                    color: '#df0029'
                  }
                },
                {
                  name: 'Obter Estado',
                  value: this.process.ticketsGetRelayStatus,
                  itemStyle: {
                    color: '#075b93'
                  }
                },
                {
                  name: 'Religamento',
                  value: this.process.ticketsRelayOn,
                  itemStyle: {
                    color: '#4caf50'
                  }
                }
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                }
              }
            },
          ],
        }
      }
    }
  }

  async getTranslate() {
    this.translateInCode
      .getTranslateEnunsInArray('Tickets.Enuns', [TicketsStatus, StatusCommand, CommandType])
      .then(res => {
        this.EnumMappersDTO = res
      });
  }

  reloadProcess() {
    this.process === null || this.process === undefined ? this.resetProcess.emit() :
      this.reload.emit(this.process.processId);
  }

  async openConnection() {
    this.connection = await this._hubServices.connect('generalHub', 'UpdateForAll');
    this.connection.onclose(async () => {
      !this.iWantToCloseWs && await this.openConnection();
    })
    this.updateProcess();
  }


  updateProcess() {
    this.connection.on('UpdateForAll', (data) => {
      data = JSON.parse(data);
      const commandType = data["type"];
      if (commandType === CommandHubType.AProcessWasUpdatedEvent) {
        let updatedProcess: IRelayActionProcess = data['payload']['processDto'];
        updatedProcess.updatedAt = CommandHubDTO.formatDate(updatedProcess.updatedAt);
        updatedProcess.tickets.forEach(_ticket => {
          _ticket.ticketCreationDate = CommandHubDTO.formatDate(_ticket.ticketCreationDate);
          _ticket.updatedAt = CommandHubDTO.formatDate(_ticket.updatedAt);
          _ticket.initialDate = CommandHubDTO.formatDate(_ticket.initialDate);
          _ticket.finishDate = CommandHubDTO.formatDate(_ticket.finishDate);
          _ticket.commandTickets.forEach(_commandTicket => {
            _commandTicket.initialDate = this.CommandHUbDTO.formatDate(
              _commandTicket.initialDate
            );
            _commandTicket.finishDate = this.CommandHUbDTO.formatDate(
              _commandTicket.finishDate
            );
          });
        });
        if (this.process.processId === updatedProcess.processId) {
          this.process = updatedProcess;
          this.tickets = updatedProcess.tickets;
        }
      }
    });
  }

  processWasCreated() {
    this.connection.on('UpdateForAll', (data) => {
      data = JSON.parse(data)
      const commandType = data["type"];
      if (commandType === CommandHubType.AProcessWasCreatedEvent) {
        let createdProcess: IRelayActionProcess = data['payload']['processDto'];
        createdProcess.updatedAt = CommandHubDTO.formatDate(createdProcess.updatedAt);
        createdProcess.tickets.forEach(_ticket => {
          _ticket.ticketCreationDate = CommandHubDTO.formatDate(_ticket.ticketCreationDate);
          _ticket.updatedAt = CommandHubDTO.formatDate(_ticket.updatedAt);
          _ticket.initialDate = CommandHubDTO.formatDate(_ticket.initialDate);
          _ticket.finishDate = CommandHubDTO.formatDate(_ticket.finishDate);
          _ticket.commandTickets.forEach(_commandTicket => {
            _commandTicket.initialDate = this.CommandHUbDTO.formatDate(
              _commandTicket.initialDate
            );
            _commandTicket.finishDate = this.CommandHUbDTO.formatDate(
              _commandTicket.finishDate
            );
          });
        });
        if (this.deviceDevEui === createdProcess.processId) {
          this.process = createdProcess;
        }
      }
    });
  }




  openModal(modalContent) {
    this._modalService.open(modalContent, {
      backdrop: 'static',
      keyboard: false,
      backdropClass: '',
      centered: true,
    });
  }

  filterTicketsBySerial(serial: string) {
    this.tickets = this.process.tickets.filter(_ticket => (_ticket.serial && _ticket.serial.includes(serial)) ||
      (_ticket.serialMeter && _ticket.serialMeter.includes(serial)));
  }

  closeModal() {
    this._modalService.dismissAll();
  }


  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
    const index = this.expandedId.indexOf(row.ticketId);
    if (index > -1) {
      this.expandedId.splice(index, 1);
    } else {
      this.expandedId.push(row.ticketId);
    }
  }

  expand() {
    this.table.rowDetail.collapseAllRows();
    for (var i = 0; i < this.process.tickets.length; i++) {
      if (this.expandedId.indexOf(this.process.tickets[i].ticketId) > -1) {
        this.table.rowDetail.toggleExpandRow(this.process.tickets[i]);
      }
    }
  }

  ngOnDestroy(): void {
    this.iWantToCloseWs = true;
    this._hubServices.disconnect(this.connection);
    this.connection = null;
  }



  navigateToProcess(event: PageEvent) {
    this.navigate.emit(event);
  }

  clearFilters() {
    this.tickets = this.process.tickets;
    this.selectedTicketStatus = null;
  }

  filterTickets() {
    this.tickets = new Array<ITicketsDTO>();
    let status: Array<string> = new Array<string>();
    this.selectedTicketStatus.forEach(_status => {
      status.push(_status);
    })
    this.process.tickets.forEach(_ticket => {
      if (status.includes(_ticket.status))
        this.tickets.push(_ticket);
    })
    this.closeModal();
  }

  downloadMeters() {
    const rows = this.processTickets();
    const workSheet: XLS.WorkSheet = XLS.utils.json_to_sheet([...rows]);
    workSheet["!cols"] = [{ width: 45 }];

    const workbook: XLS.WorkBook = {
      Sheets: {
        "Planilha1": workSheet,
      },
      SheetNames: ["Planilha1"],
    };
    const excelBuffer: any = XLS.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    this.fileService.saveFileAsExcel(excelBuffer, `Medidores do Corte/Religamento Coletivo`);
    this.closeModal();
  }



  private processTickets() {
    let metersList = [];
    this.tickets.forEach(_ticket => {
      const meter = {
        Serial: _ticket.serialMeter || _ticket.serial,
        Comando: _ticket.commandType === CommandType.RelayStatus ? 'estado' : _ticket.commandType === CommandType.RelayOff ? 'corte' : 'religa',
        Status: TicketDTO.getTicketStatus(_ticket.ticketStatus),
      }
      metersList.push(meter);
    });
    return metersList;
  }
}
