import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IActiveCalendar } from "src/app/core/models/ActiveCalendarDTO";
import { PaginationResponseDTO } from "src/app/core/models/PaginationResponseDTO";
import { IogHttpService } from "src/app/security/iog-http.service";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class DmcrSwitchService {
  static url: string = environment.apiUrlBack + '/meter-dmcr-switch';
  static commandUrl: string = environment.apiUrlBack + '/commands/';

  constructor(private httpService: IogHttpService) {}

  getBySerial(serial: string): Observable<PaginationResponseDTO> {
    const params = {
        serial,
        page: 1,
        pageSize: 15
    }
    return this.httpService.get<PaginationResponseDTO>(DmcrSwitchService.url + '/with-pagination', {params})
  }

}