export interface IFrozenDataDTO {
  dateTime: Date;
  amrProfileStatus: string;
  borderTotalizer_Wh: string;
  totalEnergy: string;
  totalEnergy_Wh: string;
  forderTotalizer_Wh: string;
  reservedTotalizer_Wh: string;
  offBorderTotalizer_Wh: string;
  totalIntermediary_Wh: string;
  generatedEnergyTotal_Wh: string;
  totalReverseActiveEnergy_Wh: string;
  generatedOffBorderEnergyTotal_Wh: string;
  generatedIntermediaryEnergyTotal_Wh: string;
  generatedBorderEnergyTotal_Wh: string;
  generatedReservedEnergyTotal_Wh: string;
  positiveReactiveEnergy_Wh: string;
  negativeReactiveEnergy_Wh: string;

  totalDirectEnergyTariffC_Wh: number;
  totalDirectEnergyTariffB_Wh: number;
  totalDirectEnergyTariffA_Wh: number;
  totalDirectEnergyTariffD_Wh: number;
  totalReverseEnergyTariffC_Wh: number;
  totalReverseEnergyTariffB_Wh: number;
  totalReverseEnergyTariffA_Wh: number;
  totalReverseEnergyTariffD_Wh: number;
  totalInductiveReactiveEnergy_VarH: number;
  totalCapacitiveReactiveEnergy_VarH: number;
  totalInductiveReactiveEnergyTariffC_Wh: number;
  totalInductiveReactiveEnergyTariffB_Wh: number;
  totalInductiveReactiveEnergyTariffA_Wh: number;
  totalInductiveReactiveEnergyTariffD_Wh: number;
  generatedEnergyTotal?: number;
  generatedOffBorderEnergyTotal?: number;
  generatedIntermediaryEnergyTotal?: number;
  generatedBorderEnergyTotal?: number;
  borderTotalizer?: number;
  reservedTotalizer?: number;
  offBorderTotalizer?: number;
  totalIntermediary?: number;
  totalReactiveInductiveEnergy?: number;
  totalReactiveCapacitiveEnergy?: number;
  consumedMaximunDemand_W?: number;
  injectedMaximunDemand_W?: number;

}
/*
 ADICIONADOS:
  public generatedEnergyTotal?: number;
  public generatedOffBorderEnergyTotal?: number;
  public generatedIntermediaryEnergyTotal?: number;
  public generatedBorderEnergyTotal?: number;
  public generatedReservedEnergyTotal?: number;
  public totalEnergy?: number;
  public borderTotalizer?: number;
  public reservedTotalizer?: number;
  public offBorderTotalizer?: number;
  public totalIntermediary?: number;
  public totalReactiveInductiveEnergy?: number;
  public totalReactiveCapacitiveEnergy?: number;
*/

export class FrozenDataDTO implements IFrozenDataDTO {
  public dateTime: Date;
  public amrProfileStatus: string; // Amr Profile Status
  public totalEnergy: string; //  Totalizador Geral
  public totalEnergy_Wh: string; //  Totalizador Geral
  public borderTotalizer_Wh: string; // Totalizador Ponta (kWh)
  public forderTotalizer_Wh: string; //  Totalizador Ponta
  public generatedEnergyTotal_Wh: string; // Energia Gerada - Total
  public reservedTotalizer_Wh: string; //  Totalizador Fora Ponta
  public offBorderTotalizer_Wh: string; //  Totalizador Reservado
  public totalIntermediary_Wh: string; //  Total Intermediário
  public totalReverseActiveEnergy_Wh: string; //  Energia Ativa Reversa Total
  public generatedOffBorderEnergyTotal_Wh: string; //  Energia Gerada Fora Ponta
  public generatedIntermediaryEnergyTotal_Wh: string; //  Energia Gerada Intermediária
  public generatedBorderEnergyTotal_Wh: string; //  Energia Gerada Ponta
  public generatedReservedEnergyTotal_Wh: string; //  Energia Gerada Reservada
  public positiveReactiveEnergy_Wh: string; // Energia Reativa Positiva
  public negativeReactiveEnergy_Wh: string; // Energia Reativa Negativa

  public totalDirectEnergyTariffC_Wh: number; // 1 Wh Totalizador de energia ativa direta na tarifa C
  public totalDirectEnergyTariffB_Wh: number; // 1 Wh Totalizador de energia ativa direta na tarifa B
  public totalDirectEnergyTariffA_Wh: number; // 1 Wh Totalizador de energia ativa direta na tarifa A
  public totalDirectEnergyTariffD_Wh: number; // 1 Wh Totalizador de energia ativa direta na tarifa D
  public totalReverseEnergyTariffC_Wh: number; // 1 Wh Totalizador de energia ativa reversa na tarifa C
  public totalReverseEnergyTariffB_Wh: number; // 1 Wh Totalizador de energia ativa reversa na tarifa B
  public totalReverseEnergyTariffA_Wh: number; // 1 Wh Totalizador de energia ativa reversa na tarifa A
  public totalReverseEnergyTariffD_Wh: number; // 1 Wh Totalizador de energia ativa reversa na tarifa D
  public totalInductiveReactiveEnergy_VarH: number; // 1 Wh Total Inductive Reactive Energy Energia Reativa Indutiva Total
  public totalInductiveReactiveEnergyTariffC_Wh: number; // 1 Wh Totalizador de energia ativa reversa na tarifa C
  public totalInductiveReactiveEnergyTariffB_Wh: number; // 1 Wh Totalizador de energia ativa reversa na tarifa B
  public totalInductiveReactiveEnergyTariffA_Wh: number; // 1 Wh Totalizador de energia ativa reversa na tarifa A
  public totalInductiveReactiveEnergyTariffD_Wh: number; // 1 Wh Totalizador de energia ativa reversa na tarifa D
  public totalCapacitiveReactiveEnergy_VarH: number; // 1 Wh Total Capacitive Reactive Energy Energia Reativa Capacitiva Total
  public consumedMaximunDemand_W: number; // W Demanda Máxima Consumida
  public injectedMaximunDemand_W: number; // W Demanda Máxima Injetada

  public generatedEnergyTotal?: number;
  public generatedOffBorderEnergyTotal?: number;
  public generatedIntermediaryEnergyTotal?: number;
  public generatedBorderEnergyTotal?: number;
  public generatedReservedEnergyTotal?: number;
  public borderTotalizer?: number;
  public reservedTotalizer?: number;
  public offBorderTotalizer?: number;
  public totalIntermediary?: number;
  public totalReactiveInductiveEnergy?: number;
  public totalReactiveCapacitiveEnergy?: number;

  static hasPoperty(list: any[], property) {
    let hasPoperty = false;
    list.forEach((item) => {
      if (item.hasOwnProperty(property)) {
        hasPoperty = true;
        return hasPoperty;
      }
    });
    return hasPoperty;
  }
}
