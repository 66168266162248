import { Component, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, OnInit } from "@angular/core";
import * as XLS from "xlsx";
import {
  MeterComissionedDTO,
  MeterDTO,
  MeterPhase,
} from "src/app/core/models/MeterDTO";
import { PaginationResponseDTO } from "src/app/core/models/PaginationResponseDTO";
import { Utils } from "src/app/shared/utils";
import { HandleErrorService } from "../../services/handle-error.service";
import { CommandsService } from '../../services/commands.service';
import { DaylightSavingService } from '../../services/daylight-saving.service';
import { DaylightSavingDto } from "src/app/core/models/DaylightSaving";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MessageService } from "../../services/message.service";
import { SelectionType } from "@swimlane/ngx-datatable";
import { MetersService } from "../../services/meters.service";

@Component({
  selector: "app-clock-table",
  templateUrl: "./clock-table.component.html",
  styleUrls: ["./clock-table.component.scss"],
})
export class ClockTableComponent implements OnChanges, OnInit {
  @Input("data") items: DaylightSavingDto[] = [];
  @Input() meter: MeterDTO;
  @Input() page: PaginationResponseDTO;
  @Input() showView = true;
  @Input() isLoading = false;
  @Input() isReloading = false;
  @Input() tickets = [];

  @Output() navigateToMeter = new EventEmitter();

  allDaylightSaving: DaylightSavingDto[] = [];

  public MeterPhase = MeterPhase;
  public MeterDTO = MeterDTO;
  public Utils = Utils;
  public MeterComissioned = MeterComissionedDTO;

  SelectionType = SelectionType;
  selected = [];



  public startDate = "";
  public endDate = "";
  public searchString = "";
  public sortOrder = "updatedAt-desc";
  public currentPage = 0;
  public pageSize = 15;
  public placeHolderLabel =
    "Pesquisar pelo serial, código de instalação , EUI do modem ou Fabricante";
  public filledColor: string = '#fff';

  @ViewChild('ngxTable', { static: false }) table: any;
  isEdit = false;
  toUpdate: DaylightSavingDto = {};
  toSave: DaylightSavingDto = {};
  toDelete: DaylightSavingDto = {};
  loadingCommands: boolean = false;
  optionStartDate: string = '1';
  optionEndDate: string = '1';
  isRequesting: boolean = false;

  week: any[] = [
    { value: 1, label: 'Domingo' },
    { value: 2, label: 'Segunda' },
    { value: 3, label: 'Terça' },
    { value: 4, label: 'Quarta' },
    { value: 5, label: 'Quinta' },
    { value: 6, label: 'Sexta' },
    { value: 7, label: 'Sábado' },
  ]

  startDateIsValid: boolean = false;
  endDateIsValid: boolean = false;

  constructor(
    private readonly _commandService: CommandsService,
    private readonly _daylightSavingService: DaylightSavingService,
    private readonly _handleErrorService: HandleErrorService,
    private readonly _ngbModal: NgbModal,
    private readonly _messageService: MessageService,
    private readonly _meterService: MetersService
  ) { }

  ngOnInit(): void {
    this.getAllDaylightSaving()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.meter.currentValue != null)
      this.getDaylightSaving()
  }

  onSelect(e) {
    console.log(e)
  }

  onActivate(event) {
    if (event.type === 'click') {
        //this.isLoadingDays = true;
        console.log(event)
        this.toUpdate = event.row;
        //await this.getByMeterProfileId(event.row);
        //this.isLoadingDays = false;

    }
  }

  selectToUpdate(row: DaylightSavingDto) {
    this.toUpdate = row;
  }

  finishOperation() {
    this._ngbModal.dismissAll();
    //this.toUpdate = {};
  }

  hasToDelete(id: string) {
    return !!Object.values(this.toDelete).length && this.toDelete.id === id
  }

  async deleteTemplate() {
    this.isRequesting = true;
    this._daylightSavingService.delete(this.toDelete.id)
    .toPromise()
    .then(res => {
      this.isRequesting = false;
      this.allDaylightSaving = this.allDaylightSaving.filter(d => d.id != this.toDelete.id);
      this.toDelete = {};
      this._messageService.showSuccess('Template', 'Template criado com sucesso!');
    }).catch(error => {
      this._handleErrorService.handle(error);
      //console.log(error)
      this.isRequesting = false;
    })
  }

  async sendCommands() {
    try {
      this.loadingCommands = true;
      const startDate = this.toUpdate.startDate.split(' ')[0].split('/').reverse().join('/')
      const startTimespan = this.toUpdate.startDate.split(' ')[1]

      const endDate = this.toUpdate.endDate.split(' ')[0].split('/').reverse().join('/')
      const endTimespan = this.toUpdate.endDate.split(' ')[1]

      await this._commandService.setDaylightSavingBegin(this.meter.serial, `${startDate} ${startTimespan}`).toPromise(),
      await this._commandService.setDaylightSavingDeviation(this.meter.serial, this.toUpdate.deviation).toPromise(),
      await this._commandService.setDaylightSavingEnd(this.meter.serial, `${endDate} ${endTimespan}`).toPromise(),
      await this._commandService.setDaylightSavingEnabled(this.meter.serial, this.toUpdate.isEnable).toPromise()

      this.finishOperation();
      this._messageService.showSuccess('Comando', 'Comando enviado com sucesso!');
      
      this.loadingCommands = false;
    } catch (error) {
      this.loadingCommands = false;
      this._handleErrorService.handle(error)
    }

  }

  async getCommands() {
    try {
      this.loadingCommands = true;
      
      await this._commandService.getDaylightSavingBegin(this.meter.serial).toPromise(),
      await this._commandService.getDaylightSavingDeviation(this.meter.serial).toPromise(),
      await this._commandService.getDaylightSavingEnd(this.meter.serial).toPromise(),
      await this._commandService.getDaylightSavingEnabled(this.meter.serial).toPromise()
    
      this.loadingCommands = false;
    } catch (error) {
      this.loadingCommands = false;
      this._handleErrorService.handle(error)
    }

  }

  openModal(content, isEdit = false) {
    this.isEdit = isEdit;
    this._ngbModal.open(content, { centered: true, windowClass : "myCustomModalClass" });
  }


  async getMeter() {
    try {
      this.isLoading = true;
      this.meter = await this._meterService.getMeterBySerial(this.meter.serial).toPromise() as MeterDTO;
      Object.assign(this.toUpdate, { 
        deviation: this.meter.deviationDaylightSaving, endDate: this.meter.endDateDaylightSaving, 
        startDate: this.meter.startDateDaylightSaving, isEnable: this.meter.isEnableDaylightSaving 
      })
      this.isLoading = false;

    } catch (error) {
      this._handleErrorService.handle(error);
      this.isLoading = false;
    }
  }

  getDaylightSaving() {
    Object.assign(this.toUpdate, { 
      deviation: this.meter.deviationDaylightSaving, endDate: this.meter.endDateDaylightSaving, 
      startDate: this.meter.startDateDaylightSaving, isEnable: this.meter.isEnableDaylightSaving 
    })
    console.log(this.toUpdate)
    this.items.push(this.toUpdate);
    this._daylightSavingService.getBySerial(this.meter.serial)
      .toPromise()
      .then(res => {
        this.items = [res];
      }).catch(error => {
        console.log(error)
      })
  }

  _createDate() {
    const data = new Date();
    const dia = ("0" + data.getDate()).slice(-2);
    const mes = ("0" + (data.getMonth() + 1)).slice(-2);
    const ano = data.getFullYear();
    const hora = ("0" + data.getHours()).slice(-2);
    const minutos = ("0" + data.getMinutes()).slice(-2); 
    const segundos = ("0" + data.getSeconds()).slice(-2);
    return `${dia}/${mes}/${ano} ${hora}:${minutos}:${segundos}`;
  }

  onInputChangeEndDate(_) {
    this.toSave.specialEndDate = this.toSave.specialEndDate.replace(/[^0-9a-zA-Z\s:/]/g, '');
    
    if (this.toSave.specialEndDate.length === 2 || this.toSave.specialEndDate.length === 5) {
      this.toSave.specialEndDate += '/';
    }

    if(this.toSave.specialEndDate.length === 10) {
      this.toSave.specialEndDate += ' ';
    }

    if(this.toSave.specialEndDate.length === 13 || this.toSave.specialEndDate.length === 16 || this.toSave.specialEndDate.length === 18) {
      this.toSave.specialEndDate += ':';
    }

    const dateRegex = /^([0-9a-zA-Z/]{2})\/([0-9a-zA-Z/]{2})\/([0-9a-zA-Z/]{4}) (\d{2}):(\d{2}):(\d{2})$/;
    this.endDateIsValid = dateRegex.test(this.toSave.specialEndDate);
  }

  onInputChange(_) {
    this.toSave.specialStartDate = this.toSave.specialStartDate.replace(/[^0-9a-zA-Z\s:/]/g, '');
    
    if (this.toSave.specialStartDate.length === 2 || this.toSave.specialStartDate.length === 5) {
      this.toSave.specialStartDate += '/';
    }

    if(this.toSave.specialStartDate.length === 10) {
      this.toSave.specialStartDate += ' ';
    }

    if(this.toSave.specialStartDate.length === 13 || this.toSave.specialStartDate.length === 16 || this.toSave.specialStartDate.length === 18) {
      this.toSave.specialStartDate += ':';
    }
    const dateRegex = /^([0-9a-zA-Z/]{2})\/([0-9a-zA-Z/]{2})\/([0-9a-zA-Z/]{4}) (\d{2}):(\d{2}):(\d{2})$/;
    this.startDateIsValid = dateRegex.test(this.toSave.specialStartDate);
  }

  normalizeDatesAndSend() {
    this.toSave.startDate = this.optionStartDate == '1' 
      ? this._createDate() 
      : this.optionStartDate == '2' 
      ? this.toSave.specialStartDate
      : Utils.formate(this.toSave.fixedStartDate);

    this.toSave.endDate = this.optionEndDate == '1' 
      ? this._createDate() 
      : this.optionEndDate == '2' 
      ? this.toSave.specialEndDate
      : Utils.formate(this.toSave.fixedEndDate);

    this._requestTemplateCreate();
  }

  _requestTemplateCreate() {
    this.isRequesting = true;
    this._daylightSavingService.create(this.toSave)
    .toPromise()
    .then(res => {
      this.isRequesting = false;
      this.allDaylightSaving.unshift(res);
      this._messageService.showSuccess('Template', 'Template criado com sucesso!');
      this.toSave = {};
      this.finishOperation();
    }).catch(error => {
      this._handleErrorService.handle(error);
      //console.log(error)
      this.isRequesting = false;
    })
  }



  getAllDaylightSaving() {
    //this.allDaylightSaving.push({ deviation: 1, endDate: new Date(), startDate: new Date, isEnable: false });

    this._daylightSavingService.getAll()
      .toPromise()
      .then(res => {
        this.allDaylightSaving = res;

      }).catch(error => {
        //this._handleErrorService.handle(error);
        console.log(error)
      
      })
  }

  navigate(serial) {
    this.navigateToMeter.emit(serial);
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }
}
