import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import * as moment from 'moment';

import { Utils } from '../../utils';
import { BILLINGREGISTER, ClusterBillingCheckingDTO, IClusterBillingCheck, BILLINPROBLEMS } from 'src/app/core/models/ClusterBillingDTO';
import { IMeterDTO } from 'src/app/core/models/MeterDTO';
import { MessageService } from 'src/app/shared/services/message.service';
import { MassMemoryServices } from 'src/app/shared/services/mass-memory.service';
import { HandleErrorService } from 'src/app/shared/services/handle-error.service';
import { MetersService } from 'src/app/shared/services/meters.service';
import { FrozenDataServices } from '../../services/frozen-data.service';

export enum ExportType {
  SAP,
  CUSTOM
}
@Component({
  selector: 'app-group-billing-table',
  templateUrl: './group-billing-table.component.html',
  styleUrls: ['./group-billing-table.component.scss']
})
export class GroupBillingTableComponent {
  @ViewChild('newDate', { static: false }) newDataModal;
  @ViewChild('NotFound', { static: false }) notFound;
  @ViewChild('allNewDates', { static: false }) allNewDate;

  @Input() file: IClusterBillingCheck[] = [];
  @Input() isLoading: boolean = false;
  @Input() preProcessing: boolean = false;
  @Input() disableSend: boolean = true;

  @Output() checkEvent = new EventEmitter();
  @Output() confirm = new EventEmitter();

  public exportType: ExportType = ExportType.SAP;

  public device: IClusterBillingCheck;
  private action: boolean = null;
  public meter: IMeterDTO = null;
  public isRecovering: boolean = false;
  public amountOfMesure: number = 3;

  public dates = {
    initialDate: null,
    finalDate: null
  }


  public devicesWithNewDate: IClusterBillingCheck[] = [];

  Utils = Utils;
  BILLINPROBLEMS = BILLINPROBLEMS;
  BILLINGREGISTER = BILLINGREGISTER;

  IClusterBillingDTO = ClusterBillingCheckingDTO;

  constructor(
    private modalService: NgbModal,
    private meterService: MetersService,
    private handleError: HandleErrorService,
    private frozenDataServices: FrozenDataServices,
    private massMemoryServices: MassMemoryServices,
    private messageService: MessageService,
  ) { }


  async openModal(content, meter?: IClusterBillingCheck) {
    this.file.forEach((_device) => {
      if (_device.billingProblem === BILLINPROBLEMS.FROZENDATA_NOT_FOUND_NEARBY || _device.billingProblem === BILLINPROBLEMS.READING_NOT_FOUND_NEARBY) this.devicesWithNewDate.push(_device)
    });
    if (meter) {
      await this.meterService.getMeterBySerial(meter.serial).toPromise().then((response: IMeterDTO) => {
        this.meter = response;
        if (this.meter.smc === null) {
          this.dates.initialDate = moment(meter.dateRequested).utc(false).subtract(1, "day").format('YYYY-MM-DD');
          this.dates.finalDate = moment(meter.dateRequested).utc(false).add(1, "day").format('YYYY-MM-DD');
        }
      }).catch(error => {
        this.handleError.handle(error)
      });
    }

    this.modalService.open(content, { centered: true, keyboard: false, backdrop: 'static' });
  }


  setMinValue() {
    if (this.amountOfMesure !== null && this.amountOfMesure < 1) this.amountOfMesure = 3;
  }

  closeModal() {
    this.modalService.dismissAll()
    this.devicesWithNewDate = [];
  }


  checkBilling(billing: IClusterBillingCheck) {
    this.checkEvent.emit(billing);
  }

  checkOneBill() {
    this.confirmCheck(this.action, this.device);
    this.closeModal();
  }

  checkAllWithNewDate() {
    this.devicesWithNewDate.forEach(_device => {
      _device.sendMeasure = true;
      this.confirmCheck(true, _device)
    }
    );
    this.closeModal();
  }


  checkAllBillings(checkEvent: boolean) {
    this.file.forEach(_billing => {
      if (!this.disabledSwicth(_billing)) {
        _billing.sendMeasure = checkEvent;
        this.confirmCheck(checkEvent, _billing);
      }
    });
  }

  recoveryReadings() {
    this.meter.smc === null ? this.recoveryFrozenData() : this.recoveryEnergy();
  }


  recoveryFrozenData() {
    this.isRecovering = true;
    this.frozenDataServices.recoverFrozenData(this.meter.serial, this.dates.initialDate, this.dates.finalDate).toPromise()
      .then(response => {
        this.messageService.showSuccess('Comando Enviado', 'Comando de Recuperação de Energia enviado');
        this.closeModal();
        this.isRecovering = false;
      })
      .catch(error => {
        this.handleError.handle(error);
        this.isRecovering = false;
      })
  }

  recoveryEnergy() {
    this.isRecovering = true;
    this.massMemoryServices.recoveryMassMemorySMC(this.meter.smc.serial, this.amountOfMesure).toPromise()
      .then(response => {
        this.messageService.showSuccess('Comando Enviado', 'Comando de Recuperação de Memória de Massa enviado!');
        this.isRecovering = false;
        this.closeModal();
      })
      .catch(error => {
        this.handleError.handle(error);
        this.isRecovering = false;
      })
  }




  disabledSwicth(billing: IClusterBillingCheck): boolean {
    return billing.billingProblem === BILLINPROBLEMS.METER_NOT_FOUND || billing.billingProblem === BILLINPROBLEMS.NO_REGISTER;
  }

  chooseCheckReason(isAdd: boolean, reason: BILLINPROBLEMS, device?: IClusterBillingCheck) {
    this.device = device;
    this.action = isAdd;

    if (isAdd) {
      switch (+reason) {
        case BILLINPROBLEMS.READING_NOT_FOUND_NEARBY:
          return this.openModal(this.newDataModal);
        case BILLINPROBLEMS.METER_NOT_FOUND:
          return this.openModal(this.notFound);
        case BILLINPROBLEMS.FROZENDATA_NOT_FOUND_NEARBY:
          return this.openModal(this.allNewDate);
        case BILLINPROBLEMS.READING_NOT_FOUND_NEARBY:
          return this.openModal(this.allNewDate);
      }
    } else if (!isAdd) {
      this.confirmCheck(false, device);
    }

  }

  cancelCheck() {
    this.device.sendMeasure = false;
    this.action = null;
    this.closeModal();
  }

  confirmCheck(action: boolean, device: IClusterBillingCheck) {
    this.checkEvent.emit({ action: action, billing: device });
  }


  confirmUpload() {
    this.confirm.emit(this.exportType);
  }

}
