import { Component, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, OnInit } from "@angular/core";
import {
  MeterComissionedDTO,
  MeterDTO,
  MeterPhase,
} from "src/app/core/models/MeterDTO";
import { PaginationResponseDTO } from "src/app/core/models/PaginationResponseDTO";
import { Utils } from "src/app/shared/utils";
import { HandleErrorService } from "../../services/handle-error.service";
import { CommandsService } from '../../services/commands.service';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SpecialDayDTO } from "src/app/core/models/SpecialDayDTO";
import { ActiveCalendarService } from "../../services/active-calendar.service";
import { IActiveCalendar, IDailyTariff, IDailyTariffTemplate, IHourlyTariff, IMeterProfile, IWeekTariff, TariffType, getTariffTypeLabel } from "src/app/core/models/ActiveCalendarDTO";
import { MessageService } from "../../services/message.service";
import { SelectionType } from "@swimlane/ngx-datatable";
import { IDropdownSettings } from "ng-multiselect-dropdown/multiselect.model";

@Component({
  selector: "active-calendar-table",
  templateUrl: "./active-calendar-table.component.html",
  styleUrls: ["./active-calendar-table.component.scss"],
})
export class ActiveCalendarTableComponent implements OnChanges, OnInit {
  @Input("data") items: IActiveCalendar[] = [];
  @Input() meter: MeterComissionedDTO;
  @Input() page: PaginationResponseDTO;
  @Input() showView = true;
  @Input() isLoading = false;
  @Input() isReloading = false;
  @Input() tickets = [];

  @Output() navigateToMeter = new EventEmitter();

  allDaylightSaving: SpecialDayDTO[] = [];

  public MeterPhase = MeterPhase;
  public MeterDTO = MeterDTO;
  public Utils = Utils;
  public MeterComissioned = MeterComissionedDTO;
  public TariffTypes = Object.values(TariffType).filter(s => Number.isInteger(s));

  public startDate = "";
  public endDate = "";
  public searchString = "";
  public sortOrder = "updatedAt-desc";
  public currentPage = 0;
  public pageSize = 15;
  public placeHolderLabel =
    "Pesquisar pelo serial, código de instalação , EUI do modem ou Fabricante";
  public filledColor: string = '#fff';

  @ViewChild('ngxTableDays', { static: false }) table: any;
  isEdit = false;
  toUpdate: IDailyTariff = { hourlyTariffs: []};
  hourlys: IHourlyTariff = { tariffType: null, time: null };
  dailyTariffs: IDailyTariff[] = [];
  dailyTariffsLoaded: IDailyTariff[] = [];
  allToUpdate: SpecialDayDTO[] = [];
  loadingCommands: boolean = false;
  name: string = '';
  weekly: IWeekTariff = {};
  date: Date;
  value: number;
  SelectionType = SelectionType;
  selected = [];

  isLoadingDays: boolean = false;

  dailyTariffTable: IDailyTariff[] = [];

  daysTemplateSelectOptions: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    allowSearchFilter: true,
    noDataAvailablePlaceholderText: '',
    searchPlaceholderText: 'Pesquise por serial',
    selectAllText: 'Selecionar todos',
    unSelectAllText: 'Remover todos',
    enableCheckAll: false,
  };

  daysTariffTemplate: IDailyTariff[] = [];

  selecteds = [];

  constructor(
    private readonly _commandService: CommandsService,
    private readonly _activeCalendarService: ActiveCalendarService,
    private readonly _handleErrorService: HandleErrorService,
    private readonly _ngbModal: NgbModal,
    private readonly _messageService: MessageService
  ) { }


  ngOnInit(): void {
    this.getAllDaylightSaving()
    this.getDailyTariff();
    this.getAllTemplates();
  }

  onSelectDay(event: IDailyTariff) {
    const index = this.dailyTariffs.findIndex(day => day.id == event.id)
    if(index == -1) {
      this.toUpdate = this.daysTariffTemplate.find(day => day.id == event.id);
      this.addDailyTariffy()
    }
  }

  onDeSelect(event) {
    const index = this.dailyTariffs.findIndex(day => day.id == event.id)
    if(index != -1) {
      this.removeDaily(index)
    }
  }

  async getAllTemplates() {
    try {
      const res = await this._activeCalendarService.getDailyTariffTemplates().toPromise();
      this.daysTariffTemplate = res.listOfItems;
    } catch (error) {
      this._handleErrorService.handle(error)
    }
  }

  isNotNullAndUndefined(num: number) {
    console.log(num)
    return num != null && num != undefined;
  }

  addHourly() {
    this.toUpdate.hourlyTariffs.push(this.hourlys)
    this.hourlys = { tariffType: null, time: null };
  }

  getTariffTypeLabel(type: TariffType) {
    return getTariffTypeLabel(Number(type))
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.meter.currentValue != null)
      this.getActiveCalendar()
  }

  selectToUpdate(row: IDailyTariffTemplate) {
    this.toUpdate = row;
  }

  finishOperation(clear = false) {
    this._ngbModal.dismissAll();
  }

  async sendActivate() {
    try {
      this.loadingCommands = true;
      await this._commandService.setActivateTimeProfile(this.meter.serial, this.date).toPromise();
      this._messageService.showSuccess('Comando', 'Comando enviado com sucesso!');
      this.loadingCommands = false;
    } catch (error) {
      this.loadingCommands = false;
      this._handleErrorService.handle(error)
    }
  }

  async sendWeek() {
    try {
      this.loadingCommands = true;
      await this._commandService.setWeekPassiveActivityCalendar(this.meter.serial, this.weekly).toPromise();
      this._messageService.showSuccess('Comando', 'Comando enviado com sucesso!');
      this.loadingCommands = false;
    } catch (error) {
      this.loadingCommands = false;
      this._handleErrorService.handle(error)
    }
  }

  async onActivate(event) {
    if (event.type === 'click') {
        this.isLoadingDays = true;
        console.log(event)
        await this.getByMeterProfileId(event.row);
        this.isLoadingDays = false;

    }
  }

  async getByMeterProfileId(row: IActiveCalendar) {
    try {
      this.isLoadingDays = true;
      //console.log(row.me)
      const res = await this._activeCalendarService.getDailyTariffByMeterId(row.meterProfileId).toPromise()
      this.dailyTariffTable = res;
      this.isLoadingDays = false;  
    } catch (error) {
      this._handleErrorService.handle(error);
      this.isLoadingDays = false;
    }

  }
  
  async getDailyTariff() {
    try {
      const res = await this._activeCalendarService.getDailyTariffBySerial(this.meter.serial).toPromise()
      this.dailyTariffsLoaded = res.listOfItems;
    } catch (error) {
      this._handleErrorService.handle(error)
    }

  }

  addDailyTariffy() {
    this.dailyTariffs.push(this.toUpdate);
    this.dailyTariffs = this.dailyTariffs.sort((a, b) => a.dayId - b.dayId)
    this.toUpdate = { hourlyTariffs: []}
  }

  async createTemplate() {
    try {
      this.loadingCommands = true;
      await this._activeCalendarService.createDailyTariffTemplate(this.toUpdate).toPromise()
      this.addDailyTariffy()
      this.loadingCommands = false;
    } catch (error) {
      this._handleErrorService.handle(error)
      this.loadingCommands = false;
    }
  }

  removeDaily(i: number) {
    var day = this.dailyTariffs.find((_, index) => i == index);
    this.dailyTariffs = this.dailyTariffs.filter((_, index) => i != index);
    this.selecteds = this.selecteds.filter(d => day.id != d.id)
  }

  toJson(obj) {
    return JSON.stringify(obj)
  }

  async sendHourly() {
    try {
      this.loadingCommands = true;
      await this._commandService.setDayPassiveActivityCalendar(this.meter.serial, this.name, this.dailyTariffs).toPromise();
      this._messageService.showSuccess('Comando', 'Comando enviado com sucesso!');
      this.loadingCommands = false;
    } catch (error) {
      this.loadingCommands = false;
      this._handleErrorService.handle(error)
    }
  }

  async sendTemplate() {
    try {
      this.loadingCommands = true;
      await this._activeCalendarService.createDailyTariffTemplate(this.toUpdate).toPromise()
      this.toUpdate = { hourlyTariffs: []};
      this.loadingCommands = false;
    } catch (error) {
      this.loadingCommands = false;
      this._handleErrorService.handle(error)
    }
  }

  removeHourly(index: number) {
    this.toUpdate.hourlyTariffs = this.toUpdate.hourlyTariffs.filter((_, i) => i != index);
  }

  async sendCommands() {
    try {
      this.loadingCommands = true;

      console.log(this.toUpdate);
      //await this._commandService.setSpecialDaysTable(this.meter.serial, this.items).toPromise(),
      this.loadingCommands = false;
    } catch (error) {
      this.loadingCommands = false;
      this._handleErrorService.handle(error)
    }

  }

  async getCommands() {
    try {
      this.loadingCommands = true;
      
      await this._commandService.getActivateTimeProfilePassiveActivityCalendar(this.meter.serial).toPromise()
      await this._commandService.getDayProfileActiveActivityCalendar(this.meter.serial).toPromise()
      await this._commandService.getDayProfilePassiveActivityCalendar(this.meter.serial).toPromise()
      await this._commandService.getWeekProfileActiveActivityCalendar(this.meter.serial).toPromise()
      await this._commandService.getWeekProfilePassiveActivityCalendar(this.meter.serial).toPromise()
      
      this.loadingCommands = false;
    } catch (error) {
      this.loadingCommands = false;
      this._handleErrorService.handle(error)
    }

  }

  openModal(content) {
    //this.toUpdate = toUpdate;
    this._ngbModal.open(content, { centered: true });
  }

  async sendStatus() {
    try {
      this.loadingCommands = true;
      await this._commandService.setActivateStausProfile(this.meter.serial, this.value).toPromise();
      this._messageService.showSuccess('Comando', 'Comando enviado com sucesso!');
      this.loadingCommands = false;
    } catch (error) {
      this.loadingCommands = false;
      this._handleErrorService.handle(error)
    }
  }

  getActiveCalendar() {
    this.isLoading = true;
    this._activeCalendarService.getBySerial(this.meter.serial)
      .toPromise()
      .then(res => {
        console.log(res)
        this.items = res;
        this.isLoading = false;
      }).catch(error => {
        this._handleErrorService.handle(error);
        console.log(error)
        this.isLoading = false;
      })
  }

  onSelect(e) {
    console.log(e)
  }

  getAllDaylightSaving() {
    //this.allDaylightSaving.push({ deviation: 1, endDate: new Date(), startDate: new Date, isEnable: false });

  /*this._specialDaysService.getAll()
      .toPromise()
      .then(res => {
        this.allDaylightSaving = res;

      }).catch(error => {
        //this._handleErrorService.handle(error);
        console.log(error)
      
      })*/
  }

  navigate(serial) {
    this.navigateToMeter.emit(serial);
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }
}
